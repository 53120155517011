import React from 'react';
import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';
import scanbg from '../assets/images/scanbg.jpg';

const PrivacyPolicy = () => {
    return (
        <Wrapper className="training-section">
        <Container>
            <div className="row justify-content-center">
                <div className="col-md-10">
                <section>
                <h3>Rhino Lister Privacy Policy Overview</h3>
                <p>Welcome to Rhino Lister! Our commitment to your privacy is paramount as you use our services accessible via www.RhinoLister.com, our APIs, and mobile applications. By engaging with our Services and clicking "I agree," you—or the entity you represent—consent to the practices described in this Privacy Policy. Should you reconsider your agreement, please cease using our Services and reach out to us directly.</p>
                </section>
                <section>
                    <h4>1. Your Personal Information</h4>
                    <ul>
                        <li>
                            <strong>What we collect:</strong>
                            <ul>
                                <li>Direct inputs from you: This includes any information you provide while interacting with our Services, like filling out forms, chatting with support, subscribing, applying for an Affiliate program, or requesting consultations. Expect to share details like your name, email, company, phone number, billing information, and more.</li>
                                <li>Affiliate specifics: If you're an Affiliate, we link your information to your unique ID and track your commissions.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Why it matters:</strong>
                            <p>Some services can't be provided without your information. If you choose not to share, you might miss out on certain features.</p>
                        </li>
                    </ul>
                </section>
                <section>
                    <h4>2. Gathering Usage Stats</h4>
                    <ul>
                        <li>
                            <strong>What we track:</strong>
                            <ul>
                                <li>Your interactions with our Services, like searches, clicks, and session details, sourced directly or via third parties.</li>
                                <li>Technical data, including how you access our Services and any diagnostic information.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>What you should know:</strong>
                            <p>Limiting data collection might restrict access to some features.</p>
                        </li>
                    </ul>
                </section>
                <section>
                    <h4>3. How We Use Your Data</h4>
                    <ul>
                        <li>
                            <strong>Our goals:</strong>
                            <ul>
                                <li>To offer, improve, and personalize our Services.</li>
                                <li>For security, payment processing, and marketing.</li>
                                <li>To keep you in the loop about changes and offers (with an easy opt-out option).</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                <section>
                    <h4>4. Sharing Your Information</h4>
                    <ul>
                        <li>
                            <strong>When we might share:</strong>
                            <ul>
                                <li>With affiliates or third parties for service enhancement.</li>
                                <li>For legal reasons or in business transfers.</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                <section>
                    <h4>5. Third-Party Engagements</h4>
                    <ul>
                        <li>We partner with third parties for various services, including advertising. They might collect data through cookies or similar technologies.</li>
                    </ul>
                </section>
                <section>
                    <h4>6. Mobile Privacy</h4>
                    <ul>
                        <li>Our app may request access to your device's camera for barcode scanning, without collecting personal info. However, data might still be collected anonymously via third parties.</li>
                    </ul>
                </section>
                <section>
                    <h4>7. About Cookies</h4>
                    <ul>
                        <li>Cookies help personalize your experience. You can manage them through your browser settings.</li>
                    </ul>
                </section>
                <section>
                    <h4>8. Do Not Track (DNT)</h4>
                    <ul>
                        <li>We currently do not respond to DNT signals from web browsers.</li>
                    </ul>
                </section>
                <section>
                    <h4>9. Managing Your Information</h4>
                    <ul>
                        <li>You can request to view, correct, or delete your personal information, subject to certain conditions.</li>
                    </ul>
                </section>
                <section>
                    <h4>10. Security and Data Storage</h4>
                    <ul>
                        <li>We employ safeguards to protect your information, stored in the US and Canada but may be processed globally.</li>
                    </ul>
                </section>
                <section>
                    <h4>11. Policy Updates</h4>
                    <ul>
                        <li>We reserve the right to update this policy, with changes taking effect after being posted on our website.</li>
                    </ul>
                </section>
                <section>
                    <h4>12 & 13. Rights for European and California Residents</h4>
                    <ul>
                        <li>Special rights under GDPR and CCPA, including access to and control over your personal information.</li>
                    </ul>
                </section>
                <section>
                    <h4>Get in Touch</h4>
                    <ul>
                        <li>For questions or concerns, please contact us at support@RhinoLister.com.</li>
                    </ul>
                </section>
                </div>
                </div>
            </Container>
        </Wrapper>

    )
}

export default PrivacyPolicy;

const Wrapper = styled.section`
width: 100%;
padding: 130px 0 70px;
background: url(${scanbg});
background-repeat:no-repeat;
background-position:center;
background-size:cover;
background-attachment: fixed;
margin: 0 auto;
padding-top: 8rem;
padding-bottom: 4rem;

.container .col-md-10 {
    background: rgba(255, 255, 255, 0.7);
    padding: 50px 60px;
    border-radius: 10px;
    h3{
        text-align:center;
    }
}

`;


