import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/images/logo23.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setShowBanner } from "../../redux/slices/bannerSlice";
import {
  setUser,
  setUserProfile,
  setUserSubscription,
  setToken,
  setIsAuthenticated,
} from "../../redux/slices/authSlice";
import loginIcon from "../../assets/images/login.svg";
import registerIcon from "../../assets/images/rigster.svg";
import profileIcon from "../../assets/images/profile.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import logoutIcon from "../../assets/images/logout.svg";
import { HeaderStyle } from "../../assets/css/commonStyle";
import { logoutApi } from "../../utils/api/auth";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.token);
  const [isSticky, setIsSticky] = useState(false);
  const [show, setShow] = useState(false);

  const scrollToTop = () => {
    setShow(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoutHandler = async () => {
    const result = await Swal.fire({
      title: "Are you sure you want to log out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "No, cancel",
      customClass: {
        confirmButton: "btn",
        cancelButton: "btn cancel-btn ",
      },
    });

    if (result.isConfirmed) {
      const response = await logoutApi(accessToken);
      if (response.status === 200) {
        localStorage.removeItem("access_token");
        Cookies.remove("discountCode");
        dispatch(setUser(""));
        dispatch(setUserProfile(""));
        dispatch(setUserSubscription(""));
        dispatch(setToken(""));
        dispatch(setIsAuthenticated(false));
        dispatch(setShowBanner({ show: false }));
        navigate("/");
      } else if (response.status == 401) {
        localStorage.removeItem("access_token");
        window.location.href = "/";
        window.location.reload();
      } else {
        console.error("Logout failed with status:", response.status);
      }
    }
  };

  return (
    <HeaderStyle
      className={isSticky ? "sticky-header active" : "sticky-header"}
    >
      <Navbar
        expand="lg"
        className="bg-body-tertiary mt-0 mb-0"
        expanded={false}
      >
        <Container>
          <NavLink to="/" onClick={scrollToTop}>
            <img src={Logo} alt="Logo" />
          </NavLink>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            variant="primary"
            className="d-lg-none"
            onClick={() => setShow(true)}
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto my-2 my-lg-0">
              <NavLink
                to="/"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/scan"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
              >
                Scan
              </NavLink>
              <NavLink
                to="/subscription"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
              >
                {!accessToken ? "Pricing" : "Subscription"}
              </NavLink>
              {accessToken && (
                <NavLink
                  to="/support"
                  onClick={scrollToTop}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "isActive" : ""
                  }
                >
                  Support
                </NavLink>
              )}
            </Nav>
            <div className="d-lg-flex login-btn">
              {!accessToken ? (
                <>
                  <NavLink to="/login" onClick={scrollToTop} className="">
                    <span>
                      <img src={loginIcon} />
                      Login
                    </span>
                  </NavLink>
                  <NavLink
                    to="/registration"
                    onClick={scrollToTop}
                    className="pending"
                  >
                    <span>
                      <img src={registerIcon} />
                      Register
                    </span>
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/myprofile/profile"
                    onClick={scrollToTop}
                    className=""
                  >
                    <span>
                      <img src={profileIcon} />
                      My Profile
                    </span>
                  </NavLink>
                  <a className="" onClick={logoutHandler}>
                    <span>
                      <img src={logoutIcon} />
                    </span>
                    Logout
                  </a>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={() => setShow(false)} responsive="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <NavLink to="/">
              <img src={Logo} alt="Logo" />
            </NavLink>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Navbar.Collapse id="navbarScroll">
            <div className="my-lg-0">
              <NavLink
                to="/"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
                data-hover=""
              >
                Home
              </NavLink>
              <NavLink
                to="/scan"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
                data-hover=""
              >
                Scan
              </NavLink>
              <NavLink
                to="/subscription"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
                data-hover=""
              >
                {!accessToken ? "Pricing" : "Subscription"}
              </NavLink>
            </div>
            <div className="d-lg-flex login-btn">
              {!accessToken ? (
                <>
                  <NavLink to="/login" onClick={scrollToTop} className="">
                    <span>
                      <img src={loginIcon} />
                      Login
                    </span>
                  </NavLink>
                  <NavLink
                    to="/registration"
                    onClick={scrollToTop}
                    className="pending"
                  >
                    <span>
                      <img src={registerIcon} />
                      Register
                    </span>
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/myprofile/profile"
                    onClick={scrollToTop}
                    className=""
                  >
                    <span>
                      <img src={profileIcon} />
                      My Profile
                    </span>
                  </NavLink>
                  <a className="" onClick={logoutHandler}>
                    <span>
                      <img src={logoutIcon} />
                    </span>
                    Logout
                  </a>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Offcanvas.Body>
      </Offcanvas>
    </HeaderStyle>
  );
};

export default Header;
