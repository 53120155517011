import React, { useEffect } from 'react';
import { ContactFormListStyle } from '../../../assets/css/adminStyle';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormik } from "formik";
import { useSelector } from 'react-redux';
import { bannerStoreApi, bannerGetApi } from '../../../utils/api/auth';
import {TailSpinButtonLoader} from '../../../utils/TailSpinLoader';
import Swal from 'sweetalert2';
import { bannerPageSchema } from '../../../utils/validations';

export const BannerPage = () => {
    const access_token = useSelector((state) => state.auth.token);
    const formik = useFormik({
        initialValues: {
            title: '',
            content: '',
            type: 'banner',
            is_active: true,
        },
        validationSchema: bannerPageSchema,
        onSubmit: async (values) => {
            const formData = {
                title: values.title,
                content: values.content,
                is_active: values.is_active,
                type: values.type
            }
            const response = await bannerStoreApi(formData, access_token);
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: 'Banner updated Successfully',
                    customClass: {
                        confirmButton: "btn",
                    },
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: 'Something went wrong',
                    customClass: {
                        confirmButton: "btn",
                    },
                });
            }
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            const response = await bannerGetApi();
            console.log(response,'resppp')
            if (response.status == 200) {
                if (response.data) {
                    formik.setValues(response.data.data);
                }
            }
        };
        fetchData();
    }, []);

    return <ContactFormListStyle>
        <div className="profile-sec">
            <h4>Banner</h4>
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col sm={12} className="mb-3 text-start">
                        <Form.Label htmlFor='title'>
                            Title
                        </Form.Label>
                        <Form.Control
                            type="text"
                            id="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.touched.title && formik.errors.title
                                ? 'is-invalid'
                                : ''
                                }`}
                        />
                        {formik.touched.title && formik.errors.title && (
                            <div className="invalid-feedback">{formik.errors.title}</div>
                        )}
                    </Col>
                    <Col sm={12} className="mb-3 text-start">
                        <Form.Label htmlFor='content'>
                            Content
                        </Form.Label>
                        <Form.Control
                            type="textarea"
                            id="content"
                            name="content"
                            as="textarea"
                            value={formik.values.content}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.touched.content && formik.errors.content
                                ? 'is-invalid'
                                : ''
                                }`}
                        />
                        {formik.touched.content && formik.errors.content && (
                            <div className="invalid-feedback">{formik.errors.content}</div>
                        )}
                    </Col>
                    <Col sm={12} className="mb-3 text-start">
                        <Form.Label htmlFor='type'>Type</Form.Label>
                        <Form.Control
                            as="select"
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.touched.type && formik.errors.type ? 'is-invalid' : ''
                                }`}
                        >
                            <option value="banner">Banner</option>
                            <option value="message_box">Message Box</option>
                        </Form.Control>
                        {formik.touched.type && formik.errors.type && (
                            <div className="invalid-feedback">{formik.errors.type}</div>
                        )}
                    </Col>

                    <Col sm={12} className="mb-3 text-start">
                        <Form.Check
                            type="switch"
                            id="isActive"
                            name="isActive" 
                            label="Activate Banner"
                            checked={formik.values.is_active}
                            onChange={() => formik.setFieldValue('is_active', !formik.values.is_active)}
                            onBlur={formik.handleBlur}
                        />
                    </Col>
                    <Col sm={12}>
                    <div className='p-0 contant-idea'>
                        <button type="submit" className="d-flex custom-btn btn-3" disabled={formik.isSubmitting}>
                            <span style={{ marginRight: formik.isSubmitting ? "5px" : "0" }}>Save</span>{" "}{formik.isSubmitting && (
                                <TailSpinButtonLoader />
                            )}
                        </button>
                    </div>
                    </Col>
                </Row>

            </Form>
        </div>
    </ContactFormListStyle>
}