import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UserSubscriptionsStyle } from "../../../assets/css/adminStyle";
import { getUsersSubscriptionPlan } from "../../../utils/api/admin";
import { useSelector } from "react-redux";
import { Table, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import TailSpinLoader from "../../../utils/TailSpinLoader";
import { userSubscriptionColumns } from "../../../utils/tableColumn";
import { timeStampToDate } from "../../../utils/common";
import { deactivateSubscription } from "../../../utils/api/subscription";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";

const UserSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [userSubscriptions, setUserSubscriptions] = useState({});
  const [btnLoading, setBtnLoading] = useState({
    userId: "",
    subId: "",
    loading: false,
  });
  const [filteredSubscriptions, setFilteredSubscriptions] = useState({});
  const accessToken = useSelector((state) => state.auth.token);

  const fetchSubscriptionData = async () => {
    try {
      const response = await getUsersSubscriptionPlan(accessToken);
      if (response.status == 200) {
        setLoading(false);
        setUserSubscriptions(response.data.userSubscriptions);
        setFilteredSubscriptions(response.data.userSubscriptions);
      } else {
        setLoading(false);
        console.error("Error fetching subscription plans");
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred", error);
    }
  };

  const handleSearchUser = (e, key) => {
    setSearchText({ ...searchText, [key]: e.target.value });
    filterData(key, e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchSubscriptionData();
  }, []);

  const filterData = (key, value) => {
    const filterUsers = userSubscriptions[key].filter(
      (record) =>
        record.user.name.toLowerCase().includes(value.toLowerCase()) ||
        record.user.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSubscriptions({ ...filteredSubscriptions, [key]: filterUsers });
  };

  if (loading) {
    return (
      <UserSubscriptionsStyle>
        <TailSpinLoader />
      </UserSubscriptionsStyle>
    );
  }

  if (filteredSubscriptions.length === 0) {
    return (
      <div className="profile-sec">
        <h5>No data found</h5>
      </div>
    );
  }

  const deleteHandler = async (userId, subId) => {
    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: "Are you sure you want to deactivate this user subscription ?",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn",
        cancelButton: "btn cancel-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setBtnLoading({ userId, subId, loading: true });
        try {
          const response = await deactivateSubscription(
            userId,
            subId,
            accessToken
          );
          setBtnLoading({});
          if (response.status == 200) {
            console.log(response, "resp");
            fetchSubscriptionData();
          } else {
            console.error("Error fetching subscription plans");
          }
        } catch (error) {
          console.error("An error occurred", error);
        }
      }
    });
  };

  function renderSubscriptionTable(key, filteredSubscriptions) {
    const data = filteredSubscriptions.map((user, index) => {
      return {
        key: index,
        name: user?.user?.name,
        email: user?.user?.email,
        created: timeStampToDate(user?.current_period_start),
        expires:
          key != "Free" ? timeStampToDate(user?.current_period_end) : "-",
        action: (
          <button
            className="btn d-flex"
            disabled={btnLoading?.loading == true}
            style={{ cursor: "pointer" }}
            onClick={() => deleteHandler(user.user.id, user.id)}
          >
            <span
              style={{
                marginRight:
                  btnLoading?.userId == user.user.id &&
                  btnLoading?.subId == user.id &&
                  btnLoading?.loading == true
                    ? "10px"
                    : "0",
              }}
            >
              Deactivate
            </span>{" "}
            {btnLoading?.userId == user.user.id &&
              btnLoading?.subId == user.id &&
              btnLoading?.loading == true && <TailSpinButtonLoader />}
          </button>
        ),
      };
    });

    return (
      <UserSubscriptionsStyle key={key} className="profile-sec">
        <h4>{key} Plan</h4>
        <Space style={{ marginBottom: 16 }}>
          <Input
            placeholder="Search by Name or Email"
            value={searchText.key}
            onChange={(e) => handleSearchUser(e, key)}
            prefix={<SearchOutlined />}
          />
        </Space>
        <div className="user-subscription-sec">
          <div className="table-scroll">
            <Table columns={userSubscriptionColumns} dataSource={data} />
          </div>
        </div>
      </UserSubscriptionsStyle>
    );
  }

  return (
    <Row>
      <Col sm={12} className="text-start">
        {Object.keys(filteredSubscriptions).map((key) =>
          renderSubscriptionTable(key, filteredSubscriptions[key])
        )}
      </Col>
    </Row>
  );
};

export default UserSubscriptions;
