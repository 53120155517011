import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setOption } from "../redux/slices/accountSlice";
import Table from "react-bootstrap/Table";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {setUserSubscription,setUser,setUserProfile} from "../redux/slices/authSlice";
import { useFormik } from "formik";
import { TailSpinButtonLoader } from "../utils/TailSpinLoader";
import { PaymentProfileApi } from "../utils/api/auth";
import { PaymentValidationSchema } from "../utils/validations";
import CountryDropdown from "../components/common/CountryDropdown";
import StatesDropdown from "../components/common/StatesDropdown";
import { buyFreePlan, buyPlan } from "../utils/api/subscription";
import { PaymentStyle } from "../assets/css/pagesStyle";
import Cookies from "js-cookie";
import { getUser } from "../utils/api/auth";
import { getParticularUserDiscountApi } from "../utils/api/auth";
import {
  checkDefaultPaymentMethod,
  checkCoupon,
} from "../utils/api/subscription";

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [coupon, setCoupon] = useState("");
  const discountCode = Cookies.get("discountCode");
  const [validationError, setValidationError] = useState("");
  const userProfile = useSelector((state) => state.auth.userProfile);
  const accessToken = useSelector((state) => state.auth.token);
  const [defaultPayment, setDefaultPayment] = useState({
    defaultPaymentExist: false,
    enableDefaultMethod: true,
    cardNumber: "",
  });
  const [newCard, showNewCard] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const userSubscription = useSelector((state) => state.auth.userSubscription);
  const [validationErrors, setValidationErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const planData = location.state ? location.state.subscriptionData : null;
  const [permanentDiscount, setPermanentDiscount] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      if (accessToken) {
        const response = await getUser(accessToken);
        if (response && response.status == 401) {
          localStorage.removeItem("access_token");
          window.location.href = "/";
          window.location.reload();
        }
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (location.state === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const checkUserDiscount = async () => {
      const response = await getParticularUserDiscountApi(user.id, accessToken);
      if (response.status == 200) {
        const res = await checkCoupon(response.data.couponCode, accessToken);
        if (res.status == 200) {
          setPermanentDiscount(res.data.data);
        }
      } else {
        setPermanentDiscount("");
        if (discountCode) {
          const response = await checkCoupon(discountCode, accessToken);
          if (response.status == 200) {
            setCoupon(response.data.data);
          } else {
            setCoupon("");
          }
        }
      }
    };
    checkUserDiscount();
  }, [discountCode]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);

    if (user?.email) {
      const checkDefaultPayment = async () => {
        try {
          const response = await checkDefaultPaymentMethod(
            user.email,
            accessToken
          );
          if (response.status == 200) {
            if (response.data.default_payment_method_exists) {
              setDefaultPayment((prevState) => ({
                ...prevState,
                defaultPaymentExist:
                  response.data.default_payment_method_exists,
                cardNumber: response.data.card,
              }));
            } else {
              showNewCard(true);
            }
          }
        } catch (error) {
          console.error("Error fetching default payment:", error);
        }
      };
      checkDefaultPayment();
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      street: userProfile.street_one,
      city: userProfile.city,
      zipcode: userProfile.zipcode,
      state: userProfile.state || "",
      country: userProfile.country || "US",
    },
    validationSchema: PaymentValidationSchema,
    onSubmit: async (values) => {
      setValidationErrors({});
      const formData = new FormData();
      formData.append("user_id", user.id);
      formData.append("full_name", user.name);
      formData.append("street_one", values.street);
      formData.append("city", values.city);
      formData.append("zipcode", values.zipcode);
      formData.append("state", values.state);
      formData.append("country", values.country);

      const response = await PaymentProfileApi(formData, accessToken);
      if (response.status === 201) {
        const userProfileData = response.data.userProfile;
        dispatch(setUserProfile(userProfileData));

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "User Updated Successfully.",
          customClass: {
            confirmButton: "btn",
          },
        });
      } else if (response.status === 422) {
        const validationErrors = response.data.errors;
        setValidationErrors(validationErrors);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Something went wrong.",
          customClass: {
            confirmButton: "btn",
          },
        });
      }
    },
  });

  const buyFreePlanHandler = async (e) => {
    e.preventDefault();
    if (!user.id) {
      return;
    }
    setProcessing(true);
    setValidationError("");
    const response = await buyFreePlan(user.id, accessToken);
    setProcessing(false);

    if (response.status === 200) {
      dispatch(setUserSubscription(response.data.subscription));
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: response.data.message,
        customClass: {
          confirmButton: "btn",
        },
      })
        .then(() => {
          navigate("/myprofile/subscription");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (response.status === 422) {
      setValidationError(response.data.error);
    } else if (response.status === 400) {
      setValidationError("An error occurred during subscription creation.");
    } else {
      setValidationError("Failed to create subscription.");
    }
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setValidationError("");
    try {
      let response;
      if (
        defaultPayment.defaultPaymentExist &&
        defaultPayment.enableDefaultMethod
      ) {
        response = await buyPlan(
          {
            coupon: permanentDiscount
              ? permanentDiscount.id
              : coupon
              ? coupon.id
              : "",
            userProfile: userProfile,
            user: user,
            useDefaultPayment: defaultPayment.enableDefaultMethod,
            planId: planData.plan_id,
          },
          accessToken
        );
      } else {
        if (!stripe || !elements) {
          return;
        }
        setProcessing(true);
        const { token, error } = await stripe.createToken(
          elements.getElement(CardElement)
        );
        if (error) {
          setProcessing(false);
          return;
        }
        response = await buyPlan(
          {
            coupon: permanentDiscount
              ? permanentDiscount.id
              : coupon
              ? coupon.id
              : "",
            userProfile,
            user,
            token: token.id,
            useDefaultPayment: false,
            planId: planData.plan_id,
          },
          accessToken
        );
      }
      if (response) {
        setProcessing(false);
        const { status, data } = response;
        if (status === 200) {
          dispatch(setUserSubscription(data.subscription));
          dispatch(setOption(data.accountSetting));
          dispatch(setUser(data.user));

          Cookies.remove("discountCode");
          const res = data.subscription;
          Swal.fire({
            icon: res.status == "incomplete" ? "warning" : "success",
            title: res.status == "incomplete" ? "Warning!" : "Success!",
            text:
              res.status == "incomplete"
                ? "Please wait until payment verification"
                : response.data.message,
            customClass: {
              confirmButton: "btn",
            },
          })
            .then(() => {
              navigate("/myprofile/subscription");
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (response.status === 422) {
          setValidationError(response.data.error);
        } else if (response.status === 400) {
          setValidationError(response.data.error);
        } else {
          setProcessing(false);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text:
              response.data.error ||
              "An error occurred during subscription creation.",
            customClass: {
              confirmButton: "btn",
            },
          });
        }
      }
    } catch (error) {
      setProcessing(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.message,
        customClass: {
          confirmButton: "btn",
        },
      });
    }
  };

  const handleNewCard = (e) => {
    e.preventDefault();
    showNewCard(true);
    setDefaultPayment((prevState) => ({
      ...prevState,
      enableDefaultMethod: false,
    }));
  };
console.log(planData,'planData');

  return (
    <PaymentStyle className="payment-sec">
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <h2>Checkout</h2>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={planData && planData.plan_id != "plan_free" ? 10 : 6}>
            <Row>
              <div className="payment-row">
                <Row>
                  <Col lg={6} className="text-start">
                    {planData && planData.plan_id != "plan_free" && (
                      <>
                        <div className="persanl">
                          <h2>Personal Details</h2>
                          <form onSubmit={formik.handleSubmit}>
                            <Table striped>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Full Name</strong>
                                  </td>
                                  <td>{user.name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Email</strong>
                                  </td>
                                  <td>{user.email}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Street</strong>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      name="street"
                                      value={formik.values.street}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.street &&
                                      formik.touched.street && (
                                        <div className="error-message">
                                          {formik.errors.street}
                                        </div>
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>City</strong>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      name="city"
                                      value={formik.values.city}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.city &&
                                      formik.touched.city && (
                                        <div className="error-message">
                                          {formik.errors.city}
                                        </div>
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Zip</strong>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      name="zipcode"
                                      value={formik.values.zipcode}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.zipcode &&
                                      formik.touched.zipcode && (
                                        <div className="error-message">
                                          {formik.errors.zipcode}
                                        </div>
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>State</strong>
                                  </td>
                                  <td>
                                    <StatesDropdown
                                      selectedState={formik.values.state}
                                      selectedCountry={formik.values.country}
                                      onBlur={formik.handleBlur}
                                      onChange={(stateValue) => {
                                        formik.setFieldValue(
                                          "state",
                                          stateValue
                                        );
                                        formik.setFieldTouched(
                                          "state",
                                          true,
                                          false
                                        );
                                      }}
                                    />
                                    {formik.errors.state && (
                                      <div className="error-message">
                                        {formik.errors.state}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Country</strong>
                                  </td>
                                  <td>
                                    <CountryDropdown
                                      selectedCountry={formik.values.country}
                                      onChange={(value) => {
                                        formik.setFieldValue("state", "");
                                        formik.setFieldValue("country", value);
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <div>
                              {Object.keys(validationErrors).length > 0 && (
                                <div>
                                  {Object.values(validationErrors).map(
                                    (error, index) => (
                                      <p key={index} className="error-message">
                                        {error}
                                      </p>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="payment-profile-btn">
                              <button
                                className="custom-btn btn-3 "
                                disabled={formik.isSubmitting}
                              >
                                <span
                                  style={{
                                    marginRight: formik.isSubmitting
                                      ? "5px"
                                      : "0",
                                  }}
                                >
                                  {" "}
                                  Save{" "}
                                </span>{" "}
                                {formik.isSubmitting && (
                                  <TailSpinButtonLoader />
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </>
                    )}
                  </Col>
                  <Col
                    lg={planData && planData.plan_id != "plan_free" ? 6 : 12}
                    className="text-start"
                  >
                    <div className="persanl absyello">
                      {planData && (
                        <React.Fragment>
                          <h2>{planData.title}</h2>
                          <ul>
                            {planData.features.map((feature, index) => (
                              <li key={index}>
                                <span>
                                  <FaCheck />
                                </span>
                                {feature}
                              </li>
                            ))}
                          </ul>
                          {!user.used_free_trial && planData.type!='advance' && planData.type!='enterprise' && (
                            <p style={{ color: "red" }}>
                              Get a 1 day Free Trial on purchasing your first
                              subscription!
                            </p>
                          )}
                          {!user.used_free_trial && (planData.type=='advance' || planData.type=='enterprise') && (
                            <p style={{ color: "red" }}>
                              Get a 30 day Free Trial on purchasing Advance or Enterprise plan on your first
                              subscription!
                            </p>
                          )}
                          {planData && planData.plan_id && (
                            <div className="subtotalrow">
                              <div className="subtotal total">
                                <strong>Total</strong>
                              </div>
                              <div
                                className="subtotal"
                                style={{
                                  textDecoration:
                                    (userSubscription &&
                                      userSubscription.cancel_at_period_end !=
                                        true &&
                                      userSubscription.plan_id != "plan_free" &&
                                      userSubscription != null &&
                                      planData.plan_id != "plan_free") ||
                                    coupon
                                      ? "line-through"
                                      : "",
                                }}
                              >
                                ${planData.price}
                              </div>
                            </div>
                          )}
                          {userSubscription &&
                            userSubscription.plan_id != "plan_free" &&
                            userSubscription.cancel_at_period_end != true &&
                            parseFloat(userSubscription.price) >
                              parseFloat(planData.price) &&
                            planData.plan_id != "plan_free" && (
                              <div>
                                <p style={{ color: "red", fontWeight: "500" }}>
                                  Price will be automatically adjusted by Stripe
                                  due to the subscription downgrade
                                </p>
                              </div>
                            )}
                          {userSubscription != null &&
                            userSubscription.plan_id != "plan_free" &&
                            userSubscription.cancel_at_period_end != true &&
                            parseFloat(userSubscription.price) <
                              parseFloat(planData.price) &&
                            planData.plan_id != "plan_free" && (
                              <div>
                                <p style={{ color: "red", fontWeight: "500" }}>
                                  Price will be automatically adjusted by Stripe
                                  due to the subscription upgrade
                                </p>
                              </div>
                            )}
                        </React.Fragment>
                      )}
                      {planData && planData.plan_id != "plan_free" ? (
                        <>
                          <div className="d-flex align-items-center justify-content-between new-card-payment mb-3">
                            <h2 className="m-0 p-0">Payment Details</h2>
                            <button
                              className="btn mr-0"
                              disabled={newCard}
                              onClick={handleNewCard}
                            >
                              Use New Card
                            </button>
                          </div>
                          <form onSubmit={handlePaymentSubmit}>
                            {defaultPayment.defaultPaymentExist && (
                              <div className="default-payment align-left">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={defaultPayment.enableDefaultMethod}
                                    onChange={(event) => {
                                      const cardElement =
                                        elements.getElement(CardElement);
                                      if (cardElement) {
                                        cardElement.clear();
                                      }
                                      if (event.target.checked == true) {
                                        showNewCard(false);
                                      } else {
                                        showNewCard(true);
                                      }
                                      setDefaultPayment((prevState) => ({
                                        ...prevState,
                                        enableDefaultMethod:
                                          event.target.checked,
                                      }));
                                    }}
                                  />
                                  Use Default Payment (Ends with{" "}
                                  {defaultPayment.cardNumber})
                                </label>
                              </div>
                            )}

                            {newCard && (
                              <CardElement options={{ hidePostalCode: true }} />
                            )}
                            {permanentDiscount ? (
                              <p style={{ color: "green" }}>
                                {`You will get ${permanentDiscount?.percent_off}% discount by Admin.`}
                              </p>
                            ) : (
                              coupon && (
                                <p style={{ color: "green" }}>
                                  {`Your coupon has been applied and you will get ${coupon?.percent_off}% discount.`}
                                </p>
                              )
                            )}
                            {validationError && (
                              <div className="error-message">
                                {validationError}
                              </div>
                            )}
                            <button
                              type="submit"
                              className="btn mt-3"
                              disabled={!stripe || processing}
                            >
                              {processing ? "Processing..." : "Pay"}
                            </button>
                          </form>
                        </>
                      ) : (
                        <>
                          <div className="free-btn">
                            <button
                              type="button"
                              onClick={buyFreePlanHandler}
                              className="btn"
                            >
                              {processing ? "Processing..." : "Buy Free Plan"}
                            </button>
                          </div>
                          {validationError && (
                            <div className="error-message">
                              {validationError}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </PaymentStyle>
  );
};

export default Payment;
