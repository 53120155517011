import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Table } from "antd";
import { TailSpin } from "react-loader-spinner";
import { inactiveSubscriptionColumns } from "../../../utils/tableColumn";
import { UserSubscriptionsStyle } from "../../../assets/css/adminStyle";
import {
  activatePlan,
  inactiveSubscriptionPlans,
} from "../../../utils/api/subscription";

const InactiveSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [usersubscriptions, setUserSubscriptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const activateHandler = (plan_id) => {
    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: "Are you sure you want to re-activate this subscription?",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn",
        cancelButton: "btn cancel-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await activatePlan(plan_id);
          if (response.status == 200) {
            setLoading(false);
            setUserSubscriptions(response.data.subscriptionPlans);
          } else {
            setLoading(false);
            console.error("Error fetching subscription plans");
          }
        } catch (error) {
          setLoading(false);
          console.error("An error occurred", error);
        }
      }
    });
  };

  // const handleDelete = (subscriptionId) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Once deleted, you will not be able to recover this subscription!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'Cancel',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const response = await axios.delete(
  //           `${API_BASE_URL}/delete-subscription/${subscriptionId}`,
  //           {
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //           }
  //         );

  //         if (response.status === 200) {
  //           Swal.fire('Deleted!', 'Your subscription has been deleted.', 'success');
  //         } else {
  //           Swal.fire('Error', 'Failed to delete the subscription.', 'error');
  //         }
  //       } catch (error) {
  //         Swal.fire('Error', 'An error occurred while deleting the subscription.', 'error');
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    setLoading(true);
    async function fetchSubscriptionData() {
      try {
        const response = await inactiveSubscriptionPlans();

        if (response.status == 200) {
          setLoading(false);
          setUserSubscriptions(response.data.subscriptionPlans);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    fetchSubscriptionData();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <TailSpin color="#E7A83E" height={50} width={50} />
      </div>
    );
  }

  function renderSubscriptionTable(usersubscriptions) {
    const data = usersubscriptions?.map((user, index) => {
      return {
        key: index,
        title: user.title,
        price: user.price,
        subscriptionType:
          user.subscriptionType.charAt(0).toUpperCase() +
          user.subscriptionType.slice(1),
        activateSubscription: (
          <button
            className="btn"
            style={{ cursor: "pointer" }}
            onClick={() => activateHandler(user.plan_id)}
          >
            Reactivate
          </button>
        ),
      };
    });

    return (
      <UserSubscriptionsStyle className="profile-sec">
        <h4>Re-activate Subscriptions </h4>
          <div className="table-scroll">
            <Table columns={inactiveSubscriptionColumns} dataSource={data} />
        </div>
      </UserSubscriptionsStyle>
    );
  }

  if (usersubscriptions.length === 0) {
    return (
      <div className="profile-sec">
        <h5>No data found</h5>
      </div>
    );
  }

  return (
    <Row>
      <Col sm={12} className="text-start">
        {renderSubscriptionTable(usersubscriptions)}
      </Col>
    </Row>
  );
};

export default InactiveSubscriptions;
