import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { categories, getSubCategories, shippingProfile, hazmat, type, descriptionTags, discountOptions } from "../../../utils/common";
import { WhatNotStyle } from '../../../assets/css/exportStyle';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';


const WhatNot = ({ whatNotData, setwhatNotData }) => {

    const [subCategoryOptions, setSubCategoryOptions] = useState([]);

    useEffect(() => {
        const subArray = getSubCategories(categories[0]);
        const transformedOptions = subArray.map(option => ({ label: option, value: option }));
        setSubCategoryOptions([...transformedOptions]);

        setwhatNotData(prevState => ({
            ...prevState,
            category: { "label": categories[0], "value": categories[0] },
            subCategory: transformedOptions[0],
            shippingProfile: { "label": shippingProfile[0], "value": shippingProfile[0] },
            hazmat: { "label": hazmat[0], "value": hazmat[0] },
            type: { "label": type[0], "value": type[0] }
        }));

    }, []);

    useEffect(() => {
        const subArray = getSubCategories(whatNotData.category.value);
        const transformedOptions = subArray.map(option => ({ label: option, value: option }));
        setSubCategoryOptions([...transformedOptions]);
        setwhatNotData(prevState => ({
            ...prevState,
            subCategory: transformedOptions[0],
        }));
    }, [whatNotData.category]);

    const handleCheckboxChange = (e, name) => {
        setwhatNotData(prevState => ({
            ...prevState,
            [name]: e.target.checked
        }));
    }

    return (
        <WhatNotStyle>
            <div className="export-row scroll-export">
                <Row>
                    <Col sm="4" className="mb-2"><p>Category</p>
                        <Select
                            name="category"
                            options={categories.map(option => ({ value: option, label: option }))}
                            value={whatNotData.category}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    category: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>
                    <Col sm="4" className="mb-2"><p>Sub Category</p>
                        <Select
                            name="subCategory"
                            options={subCategoryOptions}
                            value={whatNotData.subCategory}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    subCategory: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>
                    <Col sm="4" className="mb-2">
                        <p>Shipping Profile</p>
                        <Select
                            name="shippingProfile"
                            options={shippingProfile.map(option => ({ value: option, label: option }))}
                            value={whatNotData.shippingProfile}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    shippingProfile: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>

                    <Col sm="4"> <p>Hazmat</p>
                        <Select
                            name="hazmat"
                            options={hazmat.map(option => ({ value: option, label: option }))}
                            value={whatNotData.hazmat}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    hazmat: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>

                    <Col sm="4" className="mb-2" > <p>Type</p>
                        <Select
                            name="type"
                            options={type.map(option => ({ value: option, label: option }))}
                            value={whatNotData.type}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    type: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>
                    {whatNotData.type.value == 'Auction' &&
                        <Col sm="4">
                            <p>Start Bid</p>
                            <CurrencyInput
                                className="form-control"
                                prefix="$"
                                name="auction_start_bid"
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                defaultValue={0}
                                onValueChange={(value) => {
                                    if (value == undefined) {
                                        setwhatNotData(prevState => ({
                                            ...prevState,
                                            startBid: 0
                                        }));
                                    }
                                    if (value <= 10000) {
                                        setwhatNotData(prevState => ({
                                            ...prevState,
                                            startBid: value
                                        }));
                                    }
                                }}
                                value={whatNotData.startBid}
                            />
                        </Col>}

                    {whatNotData.type.value == 'Buy It Now' && <Col sm="4">
                        <p>Discount</p>
                        <Select
                            name="discount"
                            options={discountOptions.map(option => ({ value: option, label: option + '%' }))}
                            value={whatNotData.discount}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    discount: selectedOption
                                }));
                            }}
                        />
                    </Col>}
                </Row>
                <Row>
                    <Col className="mb-2">
                        <p>Description Tags</p>
                        <Select
                            isMulti
                            name="descriptionTags"
                            options={descriptionTags.map(option => ({ value: option, label: option }))}
                            onChange={(selectedOption) => {
                                setwhatNotData((prevState) => ({
                                    ...prevState,
                                    discountTags: selectedOption
                                }))
                            }
                            }
                        />
                    </Col>
                  
                </Row>
                <Row>
                    <Col sm="5" >
                        <div className="d-flex">
                            <Form.Check
                                type="checkbox"
                                id="priceInTitleCheckbox"
                                checked={whatNotData.priceInTitle}
                                onChange={(e) => handleCheckboxChange(e, 'priceInTitle')}
                            />
                            <Form.Label htmlFor="priceInTitleCheckbox">Add Price at end of title</Form.Label>
                        </div>
                    </Col>
                    <Col sm="7" >
                        <div className="d-flex">
                            <Form.Check
                                type="checkbox"
                                id="priceInDescCheckbox"
                                checked={whatNotData.priceInDesc}
                                onChange={(e) => handleCheckboxChange(e, 'priceInDesc')}
                            />
                            <Form.Label htmlFor="priceInDescCheckbox">Add Price at end of Description</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="5">
                        <div className="d-flex">
                            <Form.Check
                                type="checkbox"
                                id="actualWeightCheckbox"
                                checked={whatNotData.actualWeight}
                                onChange={(e) => handleCheckboxChange(e, 'actualWeight')}
                            />
                            <Form.Label htmlFor="actualWeightCheckbox">Use actual weight if available</Form.Label>
                        </div>
                    </Col>
                    <Col sm="5" className="d-flex align-items-end" >
                        <div className="d-flex">
                            <Form.Check
                                type="checkbox"
                                id="addIdTags"
                                checked={whatNotData.addIdTags}
                                onChange={(e) => handleCheckboxChange(e, 'addIdTags')}
                            />
                            <Form.Label htmlFor="addIdTags">Add ID Tags</Form.Label>
                        </div>
                    </Col>
                </Row>
            </div>
        </WhatNotStyle>
    )
}

export default WhatNot;