import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TrainingStyle } from "../assets/css/trainingStyle";
import Rhinolister_Export_Settings from "../assets/videos/Rhinolister_Export_Settings.mp4";
import Rhinolister_Scan_Product from "../assets/videos/Rhinolister_Scan_Product.mp4";
import Rhinolister_Subscriptions from "../assets/videos/Rhinolister_Subscriptions.mp4";
import Rhinolister_WhatNot_Export from "../assets/videos/Rhinolister_WhatNot_Export.mp4";

const TrainingVideos = () => {
  return (
    <TrainingStyle className="training-section">
      <Container className="text-center">
        <h2 class="mb-5">Training Videos</h2>
        <div className="videoSection">
          <Row>
            <Col sm="6">
              <div className="video-item">
                <video width="100%" controls>
                  <source src={Rhinolister_Export_Settings} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <h4>Export Settings</h4>
              </div>
            </Col>
            <Col sm="6">
              <div className="video-item">
                <video width="100%" controls>
                  <source src={Rhinolister_Scan_Product} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>Scan Product</h4>
              </div>
            </Col>
            <Col sm="6">
              <div className="video-item">
                <video width="100%" controls>
                  <source src={Rhinolister_Subscriptions} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>Subscription Plan</h4>
              </div>
            </Col>
            <Col sm="6">
              <div className="video-item">
                <video width="100%" controls>
                  <source src={Rhinolister_WhatNot_Export} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>WhatNot Export Service</h4>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </TrainingStyle>
  );
};

export default TrainingVideos;
