import React, { useState,useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { TailSpinButtonLoader } from "../../utils/TailSpinLoader";
import { setUserSubscription } from "../../redux/slices/authSlice";
import { cancelPlan } from "../../utils/api/subscription";
import { timeStampToDate } from "../../utils/common";
import { SubscriptionPlanStyle } from "../../assets/css/myProfileStyle";
import { Card } from 'antd';

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validationError,setValidationError]=useState();
  const user = useSelector((state) => state.auth.user);
  const userSubscription = useSelector((state) => state.auth.userSubscription);
  const accessToken = useSelector((state)=> state.auth.token);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  
  const cancelHandler = () => {
    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: "Are you sure you want to cancel subscription ?",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn",
        cancelButton: "btn cancel-btn"
      },
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          setValidationError('');
            const response = await cancelPlan(user.id,userSubscription.plan_id, accessToken);
            if (response.status == 200) {
              setLoading(false);
              dispatch(setUserSubscription(response.data.subscription));
            } else {
              setLoading(false);
              setValidationError('Failed to cancel subscription.');
            }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <SubscriptionPlanStyle>
      <div className="profile-sec">
        <Row className="align-items-center">
          <Col sm={6} className="text-end mb-3">
          <h4 className="text-start pb-0">My Purchased Plan</h4>
          </Col>
          <Col sm={6} className="text-end mb-3">
          <Link to="/subscription" className="custom-btn ex-btn btn-3">
              Change Subscription
            </Link>
           </Col>
        </Row>
        <div className="bass-row" style={{padding:'0.875rem 0'}}>
          {!userSubscription && <p>No subscription yet</p>}
          {userSubscription && userSubscription?.plan_id!='plan_free' && <>
            <Row>
              <Col sm={12}>
              <Card title={userSubscription.title} bordered={false} style={{ width: 300 }}>
                <p><strong>Delay btw Scans </strong>: {userSubscription?.delay_btw_scans} </p>
                <p><strong>Scans per Day</strong>: {userSubscription?.scans_per_day} </p>
                <p><strong>Started </strong>: {timeStampToDate(userSubscription.current_period_start)} </p>
                <p><strong>Current Period End </strong>: {timeStampToDate(userSubscription.current_period_end)} </p>
                <p><strong>Price </strong>: ${userSubscription.price}</p>
                <p><strong>Next Invoice </strong>: {userSubscription.status=='incomplete'?' - ': userSubscription.cancel_at_period_end ==true ? 'No further Invoice' : `$${userSubscription.latest_invoice}`}</p>
                {userSubscription.cancel_at_period_end==true ? <p><strong>Ends</strong>: <span className="planEnd">{'At period end'}</span></p>:''}
                {userSubscription.cancel_at_period_end==true ? <p><strong>Canceled At</strong>: {timeStampToDate(userSubscription.canceled_at)}</p>:''}
                <p><strong>Current Status </strong>: <span className="activePlan">{userSubscription.status=='trialing'?'Trial':userSubscription.status.charAt(0).toUpperCase() + userSubscription.status.slice(1)}</span></p>
                {userSubscription.status=='incomplete' && <p style={{color:'red',fontWeight:'500'}}>Expires tomorrow if payment isn't completed </p>}
              </Card>
              </Col>
            </Row>
          </>}
          {userSubscription && userSubscription?.plan_id=='plan_free' && 
          <> 
          <Row>
              <Col sm={12}>
              <Card title={userSubscription.title} bordered={false} style={{ width: 300 }}>
                <p><strong>Price </strong>: ${userSubscription.price}</p>
                <p><strong>Current Status </strong>:  <span className="activePlan">{userSubscription.status.charAt(0).toUpperCase() + userSubscription.status.slice(1)}</span> </p>
              </Card>
              </Col>
            </Row>
          </>
          }
          {userSubscription && <>
            <div className="error-message">{validationError}</div>
              <Row>
                <Col className="text-start mt-4">
                  {(userSubscription.status == "active" || userSubscription.status == "trialing" ||userSubscription.status == "incomplete") && userSubscription.cancel_at_period_end!=true && userSubscription.plan_id !='plan_free' &&
                    <Button onClick={() => cancelHandler(userSubscription.subscription_id)} className="btn">
                      <div className="deactivate-load"> <span>Cancel Plan</span>{" "}
                        {loading && (
                          <TailSpinButtonLoader />
                        )}
                      </div>
                    </Button>
                  }
                </Col>
              </Row>
          </>
          }
        </div>
      </div>
    </SubscriptionPlanStyle>
  );
};

export default SubscriptionPlan;