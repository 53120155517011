import React, { useState } from "react";
import {
  FormControl,
  Col,
  Container,
  Row,
  Button,
  Form,
} from "react-bootstrap";
import { FaXmark } from "react-icons/fa6";
import Swal from "sweetalert2";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import WhatNot from "./exports/WhatNot";
import CurrencyInput from "react-currency-input-field";
import {
  calculateDiscountedPrice,
  currentDateInFormat,
  discountOptions,
} from "../../utils/common";
import ShopifyModal from "./exports/ShopifyModal";
import EbayModal from "./exports/EbayModal";
import { auctionType } from "../../utils/common";
import EbayOptions from "./exports/EbayOptions";
import Select from "react-select";
import { ExportItemsStyle } from "../../assets/css/scanStyle";

const ExportItems = ({
  batchNumber,
  scanHistory,
  onClose,
  userId,
  startDate,
}) => {
  const accessToken = useSelector((state) => state.auth.token);
  const serviceOptions = useSelector((state) => state.accounts);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [showShopifyModal, setShowShopifyModal] = useState(false);
  const [showEbayModal, setShowEbayModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const allServicesAreDisable = Object.entries(serviceOptions).every(
    ([key, value]) =>
      key === "seller" ||
      key === "sellerCode" ||
      key === "auctionCatId" ||
      key === "fixedCatId" ||
      value === "disable"
  );

  //Shopify Options
  const [shopifyData, setShopifyData] = useState({
    discount: 0,
    actualWeight: false,
  });

  //Ebay Options
  const [ebayData, setEbayData] = useState({
    conditionOfItem: "1000",
    format: "FixedPriceItem",
    auctionDuration: "DAYS_1",
    startBid: 0,
    discount: 0,
    shipping: "free",
    return: "30_days",
    returnPolicy: "",
    paymentPolicy: "",
    // freeShipping: false
  });

  const [itemSpecifies, setItemSpecifies] = useState([]);

  //whatNot Options
  const [whatNotData, setwhatNotData] = useState({
    category: "",
    subCategory: "",
    shippingProfile: "",
    hazmat: "",
    type: "",
    discount: 0,
    startBid: 0,
    discountTags: [],
    addIdTags: false,
    priceInTitle: false,
    priceInDesc: false,
    actualWeight: false,
  });

  //Auction Worx
  const [auctionWorx, setAuctionWorx] = useState({
    eventId: "",
    type: { label: "Auction", value: "Auction" },
    discount: "",
    startBid: 1,
    reservePrice: { label: "0%", value: 0 },
    priceInTitle: false
  });

  //Hibid Options
  const [hibidData, setHibidData] = useState({
    startBid: 0,
  });

  const checkImagesDomain = (images) => {
    let comparedImages = [
      'https://images.homedepot.ca',
      'https://da.lowes.ca',
      'https://tshop.r10s.com',
      'cloudfront.net',
      'demandware.net',
      'r10.io',
      'https://images.frys.com',
      'https://www.brandsmartusa.com',
      'https://c1.neweggimages.com',
      'https://static.appliancesconnection.com',
      'http://www.truevalue.com',
      'rackcdn.com',
      'http://c.shld.net'
    ];
    return images.filter(image => !comparedImages.some(compImage => image.includes(compImage)));
  }

  const exportCSV = (name = "") => {
    try {
      let csvData = [];
      let headers = [];
      let filename = "";
      let newprice = "";

      const formattedDate = currentDateInFormat();

      if (name == "whatNot") {
        const MAX_TITLE_LENGTH = 150;
        const MAX_DESC_LENGTH = 999;
        let totalImageLength = 0;

        csvData = scanHistory.map((record, index) => {
          const images = record?.product_info?.Images || [];
          const imageUrls = images.filter((image) =>
            image.startsWith("https://")
          );

          totalImageLength = Math.max(totalImageLength, imageUrls.length);

          if (whatNotData.type.value === "Buy It Now") {
            newprice = calculateDiscountedPrice(
              record.price,
              whatNotData.discount.value
            );
            filename = `BuyItNow_Export_${formattedDate}`;
          } else if (whatNotData.type.value === "Auction") {
            newprice = whatNotData.startBid;
            filename = `Auction_Export_${formattedDate}`;
          } else if (whatNotData.type.value === "Giveaway") {
            filename = `Giveaway_Export_${formattedDate}`;
          }

          let discountTagsString = "";
          if (
            Array.isArray(whatNotData.discountTags) &&
            whatNotData.discountTags.length > 0
          ) {
            discountTagsString = whatNotData.discountTags
              .map((tag) => tag.label)
              .join(" : ");
          }

          const finalPrice =
            whatNotData.type.value === "Giveaway"
              ? ""
              : newprice
                ? Math.round(newprice)
                : Math.round(record.price);
          const titleSuffix = whatNotData.priceInTitle
            ? ` ($${record.price})`
            : "";
          const descSuffix = whatNotData.priceInDesc
            ? ` ($${record.price})`
            : "";

          const addTag = whatNotData.addIdTags ? `#${index + 1} ` : "";
          const availableTitleLength =
            MAX_TITLE_LENGTH - (titleSuffix.length + addTag.length);
          const availableDescLength = MAX_DESC_LENGTH - descSuffix.length;
          const truncatedTitle = record.title.substring(
            0,
            availableTitleLength
          );
          const finalDesc = record?.product_info?.Desc
            ? record.scan_id +
            " " +
            discountTagsString +
            " " +
            record.product_info.Desc
            : record.scan_id;
          const weight = whatNotData.actualWeight
            ? record.weight
            : whatNotData.shippingProfile.value;

          return [
            whatNotData.category.value,
            whatNotData.subCategory.value,
            addTag + truncatedTitle + titleSuffix,
            finalDesc.substring(0, availableDescLength) + descSuffix,
            1,
            whatNotData.type.value,
            finalPrice,
            weight || whatNotData.shippingProfile.value,
            false,
            false,
            whatNotData.hazmat.value,
            ...imageUrls,
          ];
        });

        let dynamicHeaders = [
          "Category",
          "Sub Category",
          "Title",
          "Description",
          "Quantity",
          "Type",
          "Price",
          "Shipping Profile",
          "Gradable",
          "Offerable",
          "Hazmat",
        ];
        for (let i = 1; i <= totalImageLength; i++) {
          dynamicHeaders.push(`Image URL ${i}`);
        }
        headers = dynamicHeaders;
      } else if (name == "auctionWorx") {
        const MAX_TITLE_LENGTH = 80;
        filename = `AuctionWorx_${formattedDate}`;
        let dynamicHeaders = [
          "EventID",
          "LotNumber",
          "ConsignorNumber",
          "Category",
          "Region",
          "Title",
          "Subtitle",
          "Description",
          "Price",
          "ReservePrice",
          "FixedPrice",
          "AcceptOffers",
          "IsTaxable",
        ];
        const catId =
          auctionWorx.type.value == "Auction"
            ? serviceOptions["auctionCatId"]
            : serviceOptions["fixedCatId"];

        let totalImageLength = 0;
        csvData = scanHistory.map((record, index) => {

          const titleSuffix = auctionWorx.priceInTitle
            ? ` ($${record.price})`
            : "";

          const availableTitleLength =
            MAX_TITLE_LENGTH - titleSuffix.length;
          const truncatedTitle = record.title.substring(0, availableTitleLength);

          const price = auctionWorx.type.value == "Auction"
            ? auctionWorx.startBid
            : calculateDiscountedPrice(
              record.price,
              auctionWorx.discount.value
            );

          const rPrice = auctionWorx.type.value == "Auction"
            ? auctionWorx.reservePrice.value == 0
              ? ""
              : calculateDiscountedPrice(
                record.price,
                auctionWorx.reservePrice.value
              )
            : "";
          let images = record?.product_info?.Images || [];
          images = checkImagesDomain(images);
          const imageUrls = images.filter((image) =>
            image.startsWith("https://")
          );

          totalImageLength = Math.max(totalImageLength, imageUrls.length);
          return [
            auctionWorx.eventId,
            index + 1,
            ,
            catId,
            ,
            truncatedTitle + titleSuffix,
            ,
            record?.product_info?.Desc,
            price,
            rPrice,
            ,
            "FALSE",
            "TRUE",
            ...imageUrls,
          ];
        });

        for (let i = 1; i <= totalImageLength; i++) {
          dynamicHeaders.push(`Image_${i}`);
        }
        dynamicHeaders.push(
          "YouTubeID",
          "PdfAttachments",
          "Bold",
          "Badge",
          "Highlight",
          "ShippingOptions"
        );
        headers = dynamicHeaders;
      } else if (name == "hibid") {
        filename = `Hibid_Export_${formattedDate}`;
        csvData = scanHistory.map((record, index) => [
          index + 1,
          index + 1,
          1,
          record.title,
          record?.product_info?.Desc,
          serviceOptions.seller,
          serviceOptions.sellerCode,
          hibidData.startBid,
        ]);
        headers = [
          "LotNumber",
          "SaleOrder",
          "Quantity",
          "Lead",
          "Description",
          "Seller",
          "SellerCode",
          "StartBidEach",
        ];
      } else {
        filename = `Batch_${batchNumber}_Export`;
        csvData = scanHistory.map((record) => [
          record.scan_id,
          record.title,
          record.product_info.Desc,
          record.qty,
          record.price,
        ]);
        headers = ["UPC", "Title", "Description", "Quantity", "Price"];
      }

      // Add the headers as the first row
      csvData.unshift(headers);
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${filename}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert("Your browser does not support downloading CSV files.");
      }
    } catch (error) {
      console.log(error,'error')
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "An error occurred while exporting CSV",
        customClass: {
          confirmButton: "btn",
        },
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (selectedExportOption == "") {
      return;
    }
    if (selectedExportOption == "shopify") {
      setShowShopifyModal(true);
    } else if (selectedExportOption == "eBay") {
      if (categoryId == "") {
        return;
      }

      if (itemSpecifies) {
        if (!ebayData.itemSpecifies) {
          return;
        }

        // Check if ebayData.itemSpecifies exists and has the same length as itemSpecifies array
        if (
          Object.keys(ebayData.itemSpecifies).length !== itemSpecifies.length
        ) {
          return;
        }
      }
      setShowEbayModal(true);
    } else {
      if (selectedExportOption == "whatNot") {
        exportCSV("whatNot");
      } else if (selectedExportOption == "hibid") {
        exportCSV("hibid");
      } else if (selectedExportOption == "auctionWorx") {
        exportCSV("auctionWorx");
      } else {
        exportCSV();
      }
      onClose();
    }
  };
  let dynamicClass = "";
  if (selectedExportOption == "eBay" || selectedExportOption == "whatNot") {
    dynamicClass = "export-services-modal";
  }

  const handleCheckboxChange = (e, name) => {
    setAuctionWorx(prevState => ({
      ...prevState,
      [name]: e.target.checked
    }));
  }


  return (
    <ExportItemsStyle className={`manually-section ${dynamicClass}`}>
      <Container>
        <Row className="justify-content-center">
          <Col sm={6} className="text-start">
            <div className="items-row p-4">
              <Col sm={12}>
                <h3 className="text-center">Select Option to Export</h3>
                <button className="close-btn" onClick={onClose}>
                  <FaXmark />
                </button>
              </Col>
              <div>
                <ShopifyModal
                  startDate={startDate}
                  show={showShopifyModal}
                  batchNumber={batchNumber}
                  scanHistory={scanHistory}
                  userId={userId}
                  accessToken={accessToken}
                  shopifyData={shopifyData}
                  handleClose={() => setShowShopifyModal(false)}
                  onClose={onClose}
                />
                <EbayModal
                  categoryId={categoryId}
                  ebayData={ebayData}
                  startDate={startDate}
                  show={showEbayModal}
                  batchNumber={batchNumber}
                  scanHistory={scanHistory}
                  userId={userId}
                  accessToken={accessToken}
                  handleClose={() => setShowEbayModal(false)}
                  onClose={onClose}
                />
              </div>
              {allServicesAreDisable ? (
                <div className="text-center">
                  <p>
                    Please enable your export services from Account settings.
                  </p>
                </div>
              ) : (
                <Form>
                  {serviceOptions.whatnot === "enable" && (
                    <>
                      <div className="export-options d-flex pb-2">
                        <Form.Check
                          id="whatNot"
                          name="export"
                          type="radio"
                          value="enable"
                          checked={selectedExportOption == "whatNot"}
                          onChange={() => setSelectedExportOption("whatNot")}
                        />
                        <label htmlFor="whatNot">WhatNot</label>
                      </div>
                      {selectedExportOption === "whatNot" && (
                        <WhatNot
                          whatNotData={whatNotData}
                          setwhatNotData={setwhatNotData}
                        />
                      )}
                    </>
                  )}
                  {serviceOptions.shopify === "enable" && (
                    <>
                      <div className="export-options d-flex pb-2">
                        <Form.Check
                          name="export"
                          type="radio"
                          id="shopify"
                          value="enable"
                          checked={selectedExportOption == "shopify"}
                          onChange={() => setSelectedExportOption("shopify")}
                        />
                        <label htmlFor="shopify">Shopify</label>
                      </div>
                      {selectedExportOption === "shopify" && (
                        <div className="export-row">
                          <Row>
                            <Col sm="4" className="mb-2">
                              <p>Set Discount</p>
                              <Select
                                name="shopifyDiscount"
                                value={shopifyData.discount}
                                options={discountOptions.map((option) => ({
                                  value: option,
                                  label: option + "%",
                                }))}
                                onChange={(selectedOption) => {
                                  setShopifyData({
                                    ...shopifyData,
                                    discount: selectedOption,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="d-flex">
                                <Form.Check
                                  type="checkbox"
                                  id="actualWeightCheckbox"
                                  checked={shopifyData.actualWeight}
                                  onChange={(e) =>
                                    setShopifyData({
                                      ...shopifyData,
                                      actualWeight: e.target.checked,
                                    })
                                  }
                                />
                                <Form.Label htmlFor="actualWeightCheckbox">
                                  Use actual weight if available
                                </Form.Label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                  {serviceOptions.ebay === "enable" && (
                    <>
                      <div className="export-options d-flex pb-2">
                        <Form.Check
                          name="export"
                          type="radio"
                          id="eBay"
                          value="enable"
                          checked={selectedExportOption == "eBay"}
                          onChange={() => setSelectedExportOption("eBay")}
                        />
                        <label htmlFor="eBay">eBay</label>
                      </div>
                      {selectedExportOption === "eBay" && (
                        <EbayOptions
                          setCategoryId={setCategoryId}
                          ebayData={ebayData}
                          setEbayData={setEbayData}
                          itemSpecifies={itemSpecifies}
                          setItemSpecifies={setItemSpecifies}
                        />
                      )}
                    </>
                  )}
                  {serviceOptions.hibid === "enable" && (
                    <>
                      <div className="export-options d-flex pb-2">
                        <Form.Check
                          id="hibid"
                          name="export"
                          type="radio"
                          value="enable"
                          checked={selectedExportOption == "hibid"}
                          onChange={() => setSelectedExportOption("hibid")}
                        />
                        <label htmlFor="hibid">Hibid</label>
                      </div>
                      {selectedExportOption === "hibid" && (
                        <div className="export-row">
                          <Row>
                            <Col sm="6" className="mb-2">
                              <p>Starting Bid</p>
                              <CurrencyInput
                                className="form-control"
                                prefix="$"
                                name="hibidStartBid"
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                defaultValue={0}
                                onValueChange={(value) => {
                                  if (value == undefined) {
                                    setHibidData({
                                      ...hibidData,
                                      startBid: 0,
                                    });
                                  }
                                  if (value <= 1000) {
                                    setHibidData({
                                      ...hibidData,
                                      startBid: value,
                                    });
                                  }
                                }}
                                value={hibidData.startBid}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                  {serviceOptions.manifest === "enable" && (
                    <div className="export-options d-flex pb-2">
                      <Form.Check
                        id="manifest"
                        name="export"
                        type="radio"
                        value="enable"
                        checked={selectedExportOption == "manifest"}
                        onChange={() => setSelectedExportOption("manifest")}
                      />
                      <label htmlFor="manifest">Manifest</label>
                    </div>
                  )}
                  {serviceOptions.auctionWorx === "enable" && (
                    <>
                      <div className="export-options d-flex pb-2">
                        <Form.Check
                          id="auctionWorx"
                          name="export"
                          type="radio"
                          value="enable"
                          checked={selectedExportOption == "auctionWorx"}
                          onChange={() =>
                            setSelectedExportOption("auctionWorx")
                          }
                        />
                        <label htmlFor="auctionWorx">AuctionWorx</label>
                      </div>
                      {selectedExportOption === "auctionWorx" && (
                        <div className="export-row">
                          <Row>
                            <Col sm="3" className="mb-2">
                              <Form.Label>Event ID</Form.Label>
                              <FormControl
                                type="text"
                                value={auctionWorx.eventId}
                                onChange={(e) => {
                                  setAuctionWorx({
                                    ...auctionWorx,
                                    eventId: e.target.value,
                                  });
                                }}
                              />
                            </Col>
                            <Col sm="3" className="mb-2">
                              <p>Type</p>
                              <Select
                                options={auctionType}
                                value={auctionWorx.type}
                                onChange={(selectedOption) => {
                                  setAuctionWorx((prevState) => ({
                                    ...prevState,
                                    type: selectedOption,
                                  }));
                                }}
                              />
                            </Col>
                            {auctionWorx.type.value == "Auction" && (
                              <>
                                <Col sm="3">
                                  <p>Start Bid</p>
                                  <CurrencyInput
                                    className="form-control"
                                    prefix="$"
                                    name="auction_start_bid"
                                    decimalsLimit={2}
                                    allowNegativeValue={false}
                                    defaultValue={1}
                                    onValueChange={(value) => {
                                      if (value == undefined) {
                                        setAuctionWorx((prevState) => ({
                                          ...prevState,
                                          startBid: 0,
                                        }));
                                      }
                                      if (value <= 10000) {
                                        setAuctionWorx((prevState) => ({
                                          ...prevState,
                                          startBid: value,
                                        }));
                                      }
                                    }}
                                    value={auctionWorx.startBid}
                                  />
                                </Col>
                              </>
                            )}
                            {auctionWorx.type.value == "Fixed Price" && (
                              <>
                                <Col sm="3">
                                  <p>Discount</p>
                                  <Select
                                    name="discount"
                                    options={discountOptions.map((option) => ({
                                      value: option,
                                      label: option + "%",
                                    }))}
                                    value={auctionWorx.discount}
                                    onChange={(selectedOption) => {
                                      setAuctionWorx((prevState) => ({
                                        ...prevState,
                                        discount: selectedOption,
                                      }));
                                    }}
                                  />
                                </Col>
                              </>
                            )}
                            {auctionWorx.type.value == "Auction" && (
                              <Col sm="3" className="mb-2">
                                <Form.Label>Reserve Price</Form.Label>
                                <Select
                                  name="reservePrice"
                                  options={discountOptions.map((option) => ({
                                    value: option,
                                    label: option + "%",
                                  }))}
                                  value={auctionWorx.reservePrice}
                                  onChange={(selectedOption) => {
                                    setAuctionWorx((prevState) => ({
                                      ...prevState,
                                      reservePrice: selectedOption,
                                    }));
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col sm="5">
                              <div className="d-flex">
                                <Form.Check
                                  type="checkbox"
                                  id="priceInTitleCheckbox"
                                  checked={auctionWorx.priceInTitle}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "priceInTitle")
                                  }
                                />
                                <Form.Label htmlFor="priceInTitleCheckbox">
                                  Add Price at end of title
                                </Form.Label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                  <div className="loader-export">
                    <Button
                      type="submit"
                      onClick={submitHandler}
                      className="mt-2"
                      style={{
                        background: "#E99714",
                        border: "none",
                        color: "#fff",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </ExportItemsStyle>
  );
};

export default ExportItems;
