import React, { useState ,useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { TailSpinButtonLoader } from "../utils/TailSpinLoader";
import { EmailVerifyStyle } from "../assets/css/pagesStyle";
import { ResendVerificationApi } from "../utils/api/auth";

const EmailVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const email = location.state?.email || '';
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [])

  const clickHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await ResendVerificationApi(email)
    setLoading(false);
    if (response.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Email Sent Successfully.",
        customClass: {
          confirmButton: "btn",
        },
      }).then(() => {
        navigate("/login");
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong.",
        customClass: {
          confirmButton: "btn",
        },
      })
    }
  }
  return (
    <EmailVerifyStyle >
     <center>
        <div className="email-verify">
          <h3 className="mb-3">Verify Your Email to proceed further !!!</h3>
          <Button onClick={clickHandler} className=" btn-3  d-flex">
            <span className="d-flex align-items-center" style={{ marginRight: loading ? '10px' : '0' }}>Resend Confirmation</span>
            {" "}{loading && (
              <TailSpinButtonLoader />
            )}
          </Button>
        </div>
      </center>
    </EmailVerifyStyle>
  )
}

export default EmailVerify;