import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { TailSpin } from "react-loader-spinner";
import { formatDate } from "../../../utils/common";
import { sendToEbayStore } from "../../../utils/api/accountSetting";

const EbayModal = ({
  categoryId,
  show,
  ebayData,
  scanHistory,
  batchNumber,
  userId,
  accessToken,
  handleClose,
  onClose,
  startDate,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleConfirm = async () => {
    setLoading(true);
    const response = await sendToEbayStore(scanHistory, ebayData, categoryId, userId, accessToken);
    if (response.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Please check products on ebay. if products not imported check the resective product error downloaded file. Schedule time for imported products - " + response.data.scheduleTime,
        customClass: {
          confirmButton: "btn",
        },
      })
        .then(() => {
          response.data.data.forEach((xmlString, index) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "text/xml");
            const xmlContent = new XMLSerializer().serializeToString(xmlDoc);
            const blob = new Blob([xmlContent], { type: 'text/xml' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${scanHistory[index].scan_id}.xml`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          });
          navigate(`/scan`);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong. Please try again.",
        customClass: {
          confirmButton: "btn",
        },
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className="shopify-modal ebayModalImport">
      <Modal.Header closeButton>
        <Modal.Title>Please Confirm Ebay Import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover className="modal-open">
          <tbody>
            <tr>
              <td>Date</td>
              <td>{formatDate(startDate)}</td>
            </tr>
            <tr>
              <td>Batch Number</td>
              <td>{batchNumber}</td>
            </tr>

            <tr>
              <td>No of Products imported </td>
              <td>{scanHistory.length}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="ebay-modal-button">
        <Button variant="secondary" onClick={handleConfirm} disabled={loading}>
          Confirm {loading && <TailSpin color="orange" height={18} width={18} />}
        </Button>
        <Button
          variant="primary"
          className="custom-btn btn-3 secondary btn btn-primary"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EbayModal;