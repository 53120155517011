import { Container } from "react-bootstrap";
import styled from "styled-components";
import scanbg from '../assets/images/scanbg.jpg';
const TermsOfService = () => {
    return (
        <Wrapper className="training-section">
            <Container>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <section>
                            <h3>Terms of Service</h3>
                            <p>Welcome to RhinoLister. By accessing or using our services, you agree to be bound by the Terms of Service outlined below. These terms govern your use of the RhinoLister.com ("Service"), and it is important that you review them carefully.</p>
                        </section>
                        <section>
                            <h4>1. Acceptance of Terms</h4>
                            <ul>
                                <li>
                                    <strong>1.1 Updates to Terms:</strong>
                                    <p>RhinoLister reserves the right to update these Terms at any time. By continuing to use the Service after updates are made, you agree to the revised Terms. It is your responsibility to review these Terms regularly.</p>
                                </li>
                                <li>
                                    <strong>1.2 Entire Agreement:</strong>
                                    <p>These Terms constitute the entire agreement between you and RhinoLister regarding the Service, superseding any prior agreements. No other promises or conditions in any other agreement, oral or written, shall affect this Agreement.</p>
                                </li>
                                <li>
                                    <strong>1.3 Governing Law:</strong>
                                    <p>This Agreement shall be governed by the laws applicable, without regard to its conflict of law principles.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>2. Service Provision</h4>
                            <ul>
                                <li>
                                    <strong>2.1 Access to Services:</strong>
                                    <p>RhinoLister will make the Purchased Services available to you, subject to this Agreement and for the duration of your subscription term. Your purchase is not contingent on the delivery of any future functionality or features.</p>
                                </li>
                                <li>
                                    <strong>2.2 License:</strong>
                                    <p>You are granted a limited, non-exclusive, non-transferable, and terminable license to access and use the Service for your operations.</p>
                                </li>
                                <li>
                                    <strong>2.3 Upgrades:</strong>
                                    <p>RhinoLister may periodically apply upgrades to the Service, which may change its functionality or appearance. You acknowledge and agree to such updates.</p>
                                </li>
                                <li>
                                    <strong>2.4 Right to Refuse Service:</strong>
                                    <p>To protect the servers and customer experience, RhinoLister reserves the right to refuse service at any time without warning if traffic volumes are consistently above sustainable rates.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>3. Fees and Payment</h4>
                            <ul>
                                <li>
                                    <strong>3.1 Subscription Fees:</strong>
                                    <p>Fees are based on the subscription plan and are billed monthly, including any overages from exceeding plan limits. All fees are non-refundable.</p>
                                </li>
                                <li>
                                    <strong>3.2 Taxes:</strong>
                                    <p>Fees do not include taxes, for which the customer is responsible. RhinoLister's income taxes or other taxes for which you are exempt are not included.</p>
                                </li>
                                <li>
                                    <strong>3.3 Payment Method:</strong>
                                    <p>Payments are processed in U.S. dollars via credit card only. Price adjustments for RhinoLister's services may occur at any time.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>4. Customer Registration and Responsibilities</h4>
                            <ul>
                                <li>
                                    <strong>4.1 Registration:</strong>
                                    <p>You must provide true, accurate, and complete registration information and keep your account information up to date.</p>
                                </li>
                                <li>
                                    <strong>4.2 Account Security:</strong>
                                    <p>You are responsible for maintaining the confidentiality of your account and password and for all activities under your account.</p>
                                </li>
                                <li>
                                    <strong>4.3 Accuracy of Information:</strong>
                                    <p>If any information you provide is untrue, RhinoLister has the right to suspend or terminate your account and refuse future use of the Service.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>5. Content and Use of Service</h4>
                            <ul>
                                <li>
                                    <strong>5.1 No Warranty:</strong>
                                    <p>RhinoLister does not guarantee that the Service will meet your requirements or be uninterrupted. We are not liable for any loss or damage caused by the Service.</p>
                                </li>
                                <li>
                                    <strong>5.2 Affiliate Links</strong>
                                    <p>Affiliate links may be present in the Service. RhinoLister makes no warranty regarding the removal of all affiliate links.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>6. Intellectual Property</h4>
                            <ul>
                                <li>
                                    <strong>6.1 Ownership:</strong>
                                    <p>All intellectual property rights in the Service and its content are the property of RhinoLister or its licensors. You must not use the Service in a way that infringes these rights.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>7. Termination</h4>
                            <ul>
                                <li>
                                    <strong>7.1 Termination by You:</strong>
                                    <p>You may terminate this Agreement at any time by notifying RhinoLister and refrain from using the services.</p>
                                </li>
                                <li>
                                    <strong>7.2 Termination by RhinoLister:</strong>
                                    <p>RhinoLister may terminate your access to the Service at any time, without notice, for breach of these Terms.</p>
                                </li>
                                <li>
                                    <strong>7.3 Effect of Termination:</strong>
                                    <p>Upon termination, your right to use the Service ceases, and any outstanding obligations to RhinoLister become due immediately.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h4>8. General Provisions</h4>
                            <ul>
                                <li>
                                    <strong>8.1 Amendments:</strong>
                                    <p>This Agreement may be amended only in writing agreed upon by both parties.</p>
                                </li>
                                <li>
                                    <strong>8.2 Waiver:</strong>
                                    <p>The failure of either party to enforce any right or provision will not be deemed a waiver of such right or provision.</p>
                                </li>
                            </ul>
                        </section>
                        <p>For any questions or concerns, contact RhinoLister Support support@rhinolister.com</p>
                    </div>
                </div>
            </Container>
        </Wrapper>
    )
}

export default TermsOfService;

const Wrapper = styled.section`
width: 100%;
padding: 130px 0 70px;
background: url(${scanbg});
background-repeat:no-repeat;
background-position:center;
background-size:cover;
background-attachment: fixed;
margin: 0 auto;
padding-top: 8rem;
padding-bottom: 4rem;

.container .col-md-10 {
    background: rgba(255, 255, 255, 0.7);
    padding: 50px 60px;
    border-radius: 10px;
    h3{
        text-align:center;
    }
}
`;