import React, { useMemo, useEffect } from "react";
import {
  HomeBanner,
  Service,
  FeatureSection,
  Integrations,
  Scanner,
} from "../components/home";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { HomePageStyle } from "../assets/css/pagesStyle";
import { Banner } from "./Banner";
import { getUser } from "../utils/api/auth";

const HomePage = () => {
  document.title = "Home - Rhinolister";
  const home = useSelector((state) => state.home);
  const banner = useSelector((state) => state.banner);
  const memoizedHome = useMemo(() => home, [home]);
  const accessToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUser = async () => {
      if (accessToken) {
        const response = await getUser(accessToken);
        if (response && response.status == 401) {
          localStorage.removeItem("access_token");
          window.location.href = "/";
          window.location.reload();
        }
      }
    };
    fetchUser();
  }, []);

  if (!memoizedHome.home) {
    return (
      <HomePageStyle className="homepage-loader">
        <div className="loader-container">
          <TailSpin height={80} width={80} />
        </div>
      </HomePageStyle>
    );
  }

  // Destructure home for cleaner code
  const {
    heading,
    content,
    scanContent,
    services,
    featureContent,
    shopify,
    ebay,
    amazon,
    hibid,
    whatnot,
  } = memoizedHome.home;

  return (
    <>
      {banner.type === "banner" && banner.show && (
        <Banner title={banner.title} content={banner.content} />
      )}
      <HomeBanner heading={heading} content={content} />
      <Scanner content={scanContent} />
      <Service services={services} />
      <FeatureSection content={featureContent} />
      <Integrations
        shopifyList={shopify}
        ebayList={ebay}
        amazonList={amazon}
        hibidList={hibid}
        whatnotList={whatnot}
      />
    </>
  );
};

export default HomePage;
