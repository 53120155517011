import React, { useEffect, useState } from "react";
import { checkUserStatistics } from "../../../utils/api/scan";
import { Table, Input, Space } from "antd";
import { useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import { SearchOutlined } from "@ant-design/icons";
import { userStatisticsColumns } from "../../../utils/tableColumn";
import { UserStatisticsStyle } from "../../../assets/css/adminStyle";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TailSpinLoader from "../../../utils/TailSpinLoader";

const UserStatistics = () => {
  const accessToken = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentDateData, setCurrentDateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [countScans, setCountScans] = useState({ daily: "", monthly: "" });
  ChartJS.register(ArcElement, Tooltip, Legend);

  const handleSearchUser = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const response = await checkUserStatistics(accessToken);
      setLoading(false);
      if (response.status == 200) {
        setCountScans((prev) => ({
          ...prev,
          daily: response.data.numberOfScansDaily,
          monthly: response.data.numberOfScansMonthly,
        }));
        setCurrentDateData(response.data.userData);
        setFilteredData(response.data.userData);
      } else {
        console.log("Something went wrong !!");
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const filterUsers = currentDateData.filter((record) =>
      record.user_email.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filterUsers);
  }, [searchText]);

  const data = filteredData?.map((user) => {
    return {
      email: user.user_email,
      count: user.count,
    };
  });

  const chartData = {
    labels: ["Today Scans", "No of Scans in Last 30 days"],
    datasets: [
      {
        label: "Number of Scans",
        backgroundColor: ["#FF6384", "#36A2EB"],
        borderColor: ["#FF6384", "#36A2EB"],
        borderWidth: 1,
        hoverBackgroundColor: [
          "rgba(255,99,132,0.4)",
          "rgba(54, 162, 235, 0.4)",
        ],
        hoverBorderColor: ["rgba(255,99,132,1)", "rgba(54, 162, 235, 1)"],
        data: countScans ? [countScans.daily, countScans.monthly] : [0, 0],
      },
    ],
  };

  if (loading) {
    return (
      <UserStatisticsStyle>
        <TailSpinLoader />
      </UserStatisticsStyle>
    );
  }

  return (
    <>
      <UserStatisticsStyle>
        <div className="profile-sec">
          <h4>User Statistics</h4>
          <div style={{ width: "300px", height: "300px", margin: "0 auto" }}>
            <Pie data={chartData} />
          </div>
        </div>

        <div className="profile-sec">
          <h4>Today Scans</h4>
          <Space style={{ marginBottom: 16 }}>
            <Input
              placeholder="Search by Name or Email"
              value={searchText}
              onChange={handleSearchUser}
              prefix={<SearchOutlined />}
            />
          </Space>
          {<Table columns={userStatisticsColumns} dataSource={data} />}
        </div>
      </UserStatisticsStyle>
    </>
  );
};
export default UserStatistics;
