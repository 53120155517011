export const userSubscriptionColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Created',
      key: 'created',
      dataIndex: 'created'
    },
    {
      title: 'Expires',
      key: 'expires',
      dataIndex: 'expires'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action'
    }
  ];

export const userStatisticsColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Count",
      key: "count",
      dataIndex: "count",
    },
  ];
  
export const userListColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Created',
      key: 'created',
      dataIndex: 'created'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action'
    }
  ];
  
  export const discountColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Coupon Code',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Percent Off',
      key: 'percentOff',
      dataIndex: 'percentOff'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action'
    }
  ];

  export const userDiscountColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Coupon Code',
      key: 'couponCode',
      dataIndex: 'couponCode'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action'
    }
  ];  

export const contactFormColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      key: 'phone',
      dataIndex: 'phone'
    },
    {
      title: 'Reason',
      key: 'reason',
      dataIndex: 'reason'
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date'
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: '40%'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action'
    }
  ];


  export const inactiveSubscriptionColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Subscription Type',
      key: 'subscriptionType',
      dataIndex: 'subscriptionType'
    },
    {
      title: 'Activate Subscription',
      key: 'activateSubscription',
      dataIndex: 'activateSubscription'
    }
  ];