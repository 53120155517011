import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getCategories, subCategories } from "../../../utils/api/accountSetting";
import { getPolicies } from "../../../utils/api/accountSetting";
import { Link } from "react-router-dom";

const EbayOptions = ({
  ebayData,
  setEbayData,
  setCategoryId,
  itemSpecifies,
  setItemSpecifies
}) => {

  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.auth.user);
  const [policies, setPolicies] = useState({});

  const accessToken = useSelector((state) => state.auth.token);

  //Primary Category
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState('');

  const [condition, setCondition] = useState([]);
  const [validationError, setValidationError] = useState('');

  //Sub Categories
  const [subCategoryArray, setSubCategoryArray] = useState({});
  const [subCategoryValues, setSubCategoryValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getCategories(user.id, accessToken);
      if (response.status == 200) {
        setLoading(false);
        setPrimaryCategories(response.data.categories);
      } else if (response.status == 401) {
        setLoading(false);
        setValidationError(response.data.error);
      } else {
        setLoading(false);
        setValidationError(response.data.error);
      }
    }

    const getPolicy = async () => {
      const response = await getPolicies(user.id, accessToken);
      if (response && response.status === 200) {
        const { paymentPolicies, returnPolicies, shippingPolicies } = response.data;

        setPolicies({
          ...policies,
          returnPolicies: returnPolicies,
          shippingPolicies: shippingPolicies,
          paymentPolicies: paymentPolicies
        });

        setEbayData({
          ...ebayData,
          returnPolicy: returnPolicies[0].id,
          paymentPolicy: paymentPolicies[0].id,
          shippingPolicy: shippingPolicies[0].id
        })
      }
    }
    fetchData();
    getPolicy();
  }, [user.id]);


  const handleCategoryChange = async (e) => {
    if (e.target.value) {
      setValidationError('');
      setSelectedPrimaryCategory(e.target.value);
      setLoading(true);
      const response = await subCategories(user.id, e.target.value, accessToken);
      if (response.status == 200) {
        setLoading(false);
        setSubCategoryArray({});
        setSubCategoryValues({});
        setCondition([]);
        setItemSpecifies([]);
        setSubCategoryArray(prevState => ({
          ...prevState,
          [response.data.categoryName]: response.data.categories
        }));
      } else if (response.status == 401) {
        setLoading(false);
        setValidationError(response.data.error);
      } else {
        setLoading(false);
        setValidationError(response.data.error);
      }
    }
  }

  const handleSubCategoryChange = async (index, name, val) => {
    if (val) {
      setLoading(true);
      setValidationError('');
      const response = await subCategories(user.id, val, accessToken);
      if (response.status == 200) {
        setLoading(false);
        setCondition([]);
        if (response.data.condition) {
          setCondition(response.data.condition);
        }
        if (response.data.itemSpecifies) {
          setEbayData({
            ...ebayData,
            itemSpecifies: {}
          })
          setItemSpecifies(response.data.itemSpecifies);
        }

        // Remove keys for indices above the current index
        for (let i = index + 1; i <= Object.keys(subCategoryArray).length; i++) {
          const keyToRemove = Object.keys(subCategoryArray)[i];
          setSubCategoryValues((prevState) => {
            const { [keyToRemove]: valueToRemove, ...rest } = prevState;
            return rest;
          });
          setSubCategoryArray((prevState) => {
            const { [keyToRemove]: valueToRemove, ...rest } = prevState;
            return rest;
          });
        }

        setSubCategoryValues(prevState => ({
          ...prevState,
          [name]: val
        }));

        const categories = response.data.categories;
        setCategoryId(val);
        if (categories.length > 0) {
          setItemSpecifies([]);
          setSubCategoryArray(prevState => ({
            ...prevState,
            [response.data.categoryName]: response.data.categories
          }));
          setCategoryId('');
        }
      } else {
        setLoading(false);
        setValidationError(response.data.error);
      }
    }
  }

  return (
    <div className="export-row scroll-export">
      <Row>
        <Col sm="3" className="mb-2">
          <p>Format</p>
          <select
            className="form-select"
            value={ebayData.format}
            onChange={(e) => setEbayData({
              ...ebayData,
              format: e.target.value
            })}
          >
            <option value="Chinese">Auction</option>
            <option value="FixedPriceItem">Buy It Now</option>
          </select>
        </Col>
        {ebayData.format == "FixedPriceItem" && (
          <Col sm="3" className="mb-2">
            <p>Set Discount</p>
            <select
              className="form-select"
              value={ebayData.discount}
              onChange={(e) =>
                setEbayData({
                  ...ebayData,
                  discount: e.target.value
                })}
            >
              <option value="0">Select</option>
              {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((value) => (
                <option key={value} value={value}>
                  {value}%
                </option>
              ))}
            </select>
          </Col>
        )}
        {ebayData.format == "Chinese" && (
          <>
            <Col sm="3" className="mb-2">
              <p>Auction Duration</p>
              <select
                className="form-select"
                value={ebayData.auctionDuration}
                onChange={(e) =>
                  setEbayData({
                    ...ebayData,
                    auctionDuration: e.target.value
                  })}
              >
                {[1, 3, 5, 7, 10, 30].map((value) => (
                  <option key={value} value={`Days_${value}`}>
                    {value} Days
                  </option>
                ))}
              </select>
            </Col>
            <Col sm="3" className="mb-2">
              <p>Starting Bid</p>
              <CurrencyInput
                className="form-control"
                prefix="$"
                name="ebayStartBid"
                decimalsLimit={2}
                allowNegativeValue={false}
                defaultValue={0}
                onValueChange={(value) => {
                  if (value == undefined) {
                    setEbayData({
                      ...ebayData,
                      startBid: 0
                    })
                  }
                  if (value <= 1000) {
                    setEbayData({
                      ...ebayData,
                      startBid: value
                    })
                  }
                }}
                value={ebayData.startBid}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col sm="3" className="mb-2">
          <p>Select a Category <span style={{ color: 'red' }}>*</span></p>
          <select
            className="form-select"
            value={selectedPrimaryCategory}
            onChange={handleCategoryChange}
          >
            <option value=''>
              Select Category
            </option>
            {
              primaryCategories.map((category) => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              ))
            }
          </select>
        </Col>
        {Object.entries(subCategoryArray).map(([name, subCategories], index) => {
          return (
            <Col key={name} sm="3" className="mb-2">
              <div >
                <p>{name} <span style={{ color: 'red' }}>*</span></p>
                <select
                  className="form-select"
                  value={subCategoryValues[name] || ''}
                  onChange={(e) => handleSubCategoryChange(index, name, e.target.value)}
                >
                  <option value=''>Select Category</option>
                  {subCategories.map((category) => (
                    <option key={category.categoryId} value={category.categoryId}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          )
        }
        )}
        {condition.length > 0 && <Col sm="3" className="mb-2">
          <p>Condition of item</p>
          <select
            className="form-select"
            value={ebayData.conditionOfItem}
            onChange={(e) => setEbayData({
              ...ebayData,
              conditionOfItem: e.target.value
            })}
          >
            {condition.map((conditionOption) => (
              <option key={conditionOption.conditionId} value={conditionOption.conditionId}>
                {conditionOption.conditionDescription}
              </option>
            ))}
          </select>
        </Col>
        }
        {itemSpecifies?.map((item, index) => {
          return (
            <Col key={index + 1} sm="3" className="mb-2">
              <div >
                <p>{item.localizedAspectName} <span style={{ color: 'red' }}>*</span></p>
                {item.aspectValues ? (
                  <select
                    className="form-select"
                    value={ebayData?.itemSpecifies?.[item.localizedAspectName] || ''}
                    onChange={(e) => setEbayData({
                      ...ebayData,
                      itemSpecifies: {
                        ...ebayData.itemSpecifies,
                        [item.localizedAspectName]: e.target.value
                      }
                    })}
                  >
                    <option value="">Select Category</option>
                    {item.aspectValues.map((category) => (
                      <option key={category.localizedValue} value={category.localizedValue}>
                        {category.localizedValue}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    value={ebayData?.itemSpecifies?.[item.localizedAspectName] || ''}
                    onChange={(e) =>
                      setEbayData({
                        ...ebayData,
                        itemSpecifies: {
                          ...ebayData.itemSpecifies,
                          [item.localizedAspectName]: e.target.value
                        }
                      })
                    }
                  />
                )}
              </div>
            </Col>
          )
        }
        )}
      </Row>
      <Row>
        <Col sm="3" className="mb-2">
          <div >
            <p>Payment Policy <span style={{ color: 'red' }}>*</span></p>
            <select
              className="form-select"
              value={ebayData.paymentPolicy}
              onChange={(e) => setEbayData({
                ...ebayData,
                paymentPolicy: e.target.value
              })}
            >
              {policies?.paymentPolicies?.map(policy => (
                <option key={policy.id} value={policy.id}>
                  {policy.name}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col sm="3" className="mb-2">
          <div >
            <p>Return Policy <span style={{ color: 'red' }}>*</span></p>
            <select
              className="form-select"
              value={ebayData.returnPolicy}
              onChange={(e) => setEbayData({
                ...ebayData,
                returnPolicy: e.target.value
              })}
            >
              {policies?.returnPolicies?.map(policy => (
                <option key={policy.id} value={policy.id}>
                  {policy.name}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col sm="3" className="mb-2">
          <div >
            <p>Shipping Policy <span style={{ color: 'red' }}>*</span></p>
            <select
              className="form-select"
              value={ebayData.shippingPolicy}
              onChange={(e) => setEbayData({
                ...ebayData,
                shippingPolicy: e.target.value
              })}
            >
              {policies?.shippingPolicies?.map(policy => (
                <option key={policy.id} value={policy.id}>
                  {policy.name}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-end"><Link style={{color:'rgb(233, 151, 20)',textDecoration:'none',position: 'relative',
    top: '10px'}} to='/export-instructions'>Check the Export Instructions for eBay</Link></Col>
      </Row>
      <div className="ebayLoader mt-2">
        {validationError && <div className="error-message">{validationError}</div>}
        {
          loading && (
            <TailSpin color="#E7A83E" height={22} width={22} />
          )}
      </div>

    </div>
  );
};

export default EbayOptions;