import React,{useEffect} from "react";
import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';
import scanbg from '../assets/images/scanbg.jpg';

const ExportInstructions = () => {
    useEffect(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 300);
    },[]);
    return (
        <Wrapper className="training-section">
            <Container>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <section>
                            <h3>Shopify Instructions</h3>
                            <p>To import products to your Shopify store, you'll need to provide the Shopify store URL and an access token. Follow these instructions to ensure a successful product import:</p>
                            <ul>
                                <li>
                                    <strong>Shopify Store URL:</strong>
                                    <ul>
                                        <li>You must have a Shopify store set up and accessible via a URL. If you haven't already, create a Shopify store at Shopify.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Access Token:</strong>
                                    <ul>
                                        <li>Generate an access token for your Shopify store. This token is necessary for our platform to access your store and import products.</li>
                                    </ul>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Ebay Instructions</h3>
                            <p>To export products to eBay from our platform, follow these steps:</p>
                            <ul>
                                <li>
                                    <strong>Authentication:</strong>
                                    <ul>
                                        <li> Before exporting products, you need to authenticate your eBay account with our platform.
                                            Navigate to the authentication section within our platform and follow the prompts to link your eBay account securely.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Ensure eBay Policies:</strong>
                                    <ul>
                                        <li> Ensure that your eBay account has the necessary policies set up:</li>
                                        <li>Payment Policy: Define accepted payment methods and terms.</li>
                                        <li> Return Policy: Specify return conditions and timeframes.</li>
                                        <li> Shipping Policy: Outline shipping methods, costs, and handling times.</li>
                                        <li> These policies are required by eBay and must be configured in your eBay account before exporting products.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong> Export Products:</strong>
                                    <ul>
                                        <li> Once authenticated and policies are set up, proceed to the product export section in our platform.
                                            Select the products you want to export to eBay and follow the export process.
                                            Review and confirm the export settings, including pricing, descriptions, and images.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong> Verification:</strong>
                                    <ul>
                                        <li>  After exporting products, verify that they are successfully listed on your eBay account.
                                            Check for any errors or missing information in the product listings.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Download XML File for Troubleshooting:</strong>
                                    <ul>
                                        <li> In case any products fail to import to eBay, you can download an XML file containing details of the failed products.
                                            Use this XML file to identify and troubleshoot any issues with the failed product listings.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Product Scheduling:</strong>
                                    <ul>
                                        <li>Products exported to eBay will be scheduled to go live after 7 days of import.
                                            This delay allows for review and adjustments before products become visible to eBay users.</li>
                                    </ul>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>WhatNot Instructions</h3>
                            <p>To import products to WhatNot, follow these steps:</p>
                        </section>
                    </div>
                </div>
            </Container>
        </Wrapper>
    )
}
export default ExportInstructions;

const Wrapper = styled.section`
width: 100%;
padding: 130px 0 70px;
background: url(${scanbg});
background-repeat:no-repeat;
background-position:center;
background-size:cover;
background-attachment: fixed;
margin: 0 auto;
padding-top: 8rem;
padding-bottom: 4rem;

.container .col-md-10 {
    background: rgba(255, 255, 255, 0.7);
    padding: 50px 60px;
    border-radius: 10px;
    h3{
        text-align:center;
    }
}

`;
