import styled from "styled-components";
import scanbg from '../images/scanbg.jpg';
import contentbg from '../images/barcode-banner.jpg';
import arrowWhite from '../images/arrow-white.png';

export const SupportStyle=styled.section`
width: 100%;
  text-align: left;
  background-size:cover;
  padding: 4rem 0 0;
  .accordion-button:focus {
    border-color: none;
    box-shadow: none;
}
    .accordion-sec button.btn.btn-primary {
        width: 288px;
    }
    .video-btn {
        text-align: center;
    }

  .training-videos {
    background: url(${contentbg});
    width: 100%;
    text-align: left;
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative
}
.training-videos:after{
    background: rgba(0,0,0,0.8);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.training-videos .container {
    position: relative;
    z-index: 1;
}
.training-videos p {
    line-height: 1.75rem;
    color: #ffffff;
    margin-bottom: 30px;
}

.support-form{
    background: url(${scanbg});
    padding-top: 3rem;
    padding-bottom: 3rem;

}
.form-c{
padding: 40px;
    background: #fff;
    border-radius: 10px;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(${arrowWhite})!important;
    transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    outline:none;
}

`; 