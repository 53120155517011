import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { SupportStyle } from "../assets/css/supportStyle";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { TailSpinButtonLoader } from "../utils/TailSpinLoader";
import { requestOptions } from "../utils/common";
import { contactFormValidationSchema } from "../utils/validations";
import FAQ from "./FAQ";
import PhoneInput from "react-phone-number-input";
import { contactForm } from "../utils/api/accountSetting";
import Select from "react-select";
import { getUser } from "../utils/api/auth";
import "react-phone-number-input/style.css";

const Support = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [validationErrors, setValidationErrors] = useState({});
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accessToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUser = async () => {
      if (accessToken) {
        const response = await getUser(accessToken);
        if (response && response.status == 401) {
          localStorage.removeItem("access_token");
          window.location.href = "/";
          window.location.reload();
        }
      }
    };
    fetchUser();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      description: "",
      phone: "",
      reason: "",
    },
    validationSchema: contactFormValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setValidationErrors({});
        const response = await contactForm(user.id, values, accessToken);
        if (response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Form is submitted Successfully.",
            customClass: {
              confirmButton: "btn",
            },
          });
          resetForm();
        } else if (response.status === 422) {
          const validationErrors = response.data.errors;
          setValidationErrors(validationErrors);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Something went wrong.",
            customClass: {
              confirmButton: "btn",
            },
          });
        }
      } catch (error) {
        console.error("Submission failed:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <SupportStyle>
      <div className="training-videos">
        <Container>
          <div className="video-btn">
            <Button onClick={() => navigate("/training-videos")}>
              Training/Support Videos
            </Button>
          </div>
        </Container>
      </div>
      <div className="accordion-sec">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <FAQ />
            </div>
          </div>
        </Container>
      </div>
      <div className="support-form">
        <Container>
          <Row className="justify-content-center text-center ">
            <Col sm={12} lg={6}>
              <h2 className="pb-2">Have a question ?</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={12} lg={6}>
              <Form className="form-c" onSubmit={formik.handleSubmit}>
                {Object.keys(validationErrors).map((fieldName) => (
                  <p key={fieldName} className="error-message">
                    {validationErrors[fieldName]}
                  </p>
                ))}
                <Row className="mb-3">
                  <Form.Group
                    className="mb-2 col-sm-6"
                    controlId="formGridAddress1"
                  >
                    <Form.Control
                      placeholder="Name"
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className=" error-message">{formik.errors.name}</div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-2 col-sm-6"
                    controlId="formGridEmail"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      disabled={isAuthenticated}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error-message">{formik.errors.email}</div>
                    )}
                  </Form.Group>
                  <Form.Group
                    className="mb-2 col-sm-12"
                    controlId="formGridPhone"
                  >
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formik.values.phone}
                      className="form-control"
                      name="phone"
                      onChange={(phoneNumber) =>
                        formik.setFieldValue("phone", phoneNumber)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="error-message">{formik.errors.phone}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-2 col-sm-12">
                    <Select
                      name="reason"
                      options={requestOptions}
                      placeholder="Reason For Request"
                      value={
                        formik.values.reason
                          ? {
                              label: formik.values.reason,
                              value: formik.values.reason,
                            }
                          : null
                      }
                      onChange={(selectedOption) => {
                        formik.setFieldValue("reason", selectedOption.value);
                      }}
                    />
                    {formik.touched.reason && formik.errors.reason && (
                      <div className="error-message ">
                        {formik.errors.reason}
                      </div>
                    )}
                  </Form.Group>
                  <Col sm={12}>
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "120px" }}
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div className="error-message">
                          {formik.errors.description}
                        </div>
                      )}
                  </Col>
                </Row>
                <Button
                  className="d-flex "
                  variant="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <span
                    style={{ marginRight: formik.isSubmitting ? "5px" : "0" }}
                  >
                    Submit
                  </span>{" "}
                  {formik.isSubmitting && <TailSpinButtonLoader />}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </SupportStyle>
  );
};

export default Support;
