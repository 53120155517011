import React, { useEffect, useState } from "react";
import { SubscriptionStyle } from "../../../assets/css/adminStyle";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { Table } from "antd";
import Select from "react-select";
import { DeleteUserDiscountApi } from "../../../utils/api/auth";
import { UserDiscountSchema } from "../../../utils/validations";
import { userDiscountColumns } from "../../../utils/tableColumn";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";
import { fetchUserList } from "../../../utils/api/admin";
import { getCoupons } from "../../../utils/api/subscription"; 
import { UserDiscountApi, GetAllUsersDiscountApi } from "../../../utils/api/auth";

const UserDiscount = () => {
  const [users, setUsers] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [discountUsers, setDiscountUsers] = useState([]);
  const accessToken = useSelector((state) => state.auth.token);

  const fetchUsers = async () => {
    try {
      const response = await fetchUserList(accessToken);
      if (response.status == 200) {
        setUsers(response.data.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersDiscount = async () => {
    try {
      const response = await GetAllUsersDiscountApi(accessToken);
      if (response.status == 200) {
        setDiscountUsers(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCoupons = async () => {
    try {
      const response = await getCoupons(accessToken);
      if (response.status == 200) {
        setCoupons(response.data.coupons.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCoupons();
    fetchUsers();
    getUsersDiscount();
  }, []);


  const formik = useFormik({
    initialValues: {
      userId: "",
      couponCode: "",
    },
    validationSchema: UserDiscountSchema,
    onSubmit: async (values, { resetForm }) => {
      const response = await UserDiscountApi(values, accessToken);
      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text:
            response.status == 201
              ? "Discount created Successfully!"
              : "Discount updated Successfully!",
          customClass: {
            confirmButton: "btn",
          },
        }).then(() => {
          resetForm();
          getUsersDiscount();
        });
      }
    },
  });

  const deleteHandler = async (e, userId) => {
    e.preventDefault();
    try {
      const result = await Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Are you sure you want to delete it?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn",
          cancelButton: "btn cancel-btn",
        },
      });

      if (result.isConfirmed) {
        const response = await DeleteUserDiscountApi(userId, accessToken);
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Discount deleted Successfully!",
            customClass: {
              confirmButton: "btn",
            },
          }).then(() => {
            getUsersDiscount();
          });
        }
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const data =
    discountUsers &&
    discountUsers.map((list, index) => {
      return {
        key: index,
        name: list.user.name,
        email: list.user.email,
        couponCode: list.couponCode,
        action: (
          <div style={{ cursor: "pointer" }}>
            <FaTrash
              title="Delete"
              onClick={(e) => deleteHandler(e, list.user_id)}
            />
          </div>
        ),
      };
    });

  return (
    <>
      <SubscriptionStyle>
        <Form onSubmit={formik.handleSubmit}>
          <div className="subscription-plan-container">
            <div className="profile-sec">
              <Row>
                <Col sm={12} className="text-start">
                  <h4>Add Discount to User</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3 text-start">
                  <Form.Group>
                    <Form.Label>Select User</Form.Label>
                    <Select
                      name="userId"
                      value={
                        users.find((user) => user.id === formik.values.userId)
                          ? {
                              value: formik.values.userId,
                              label:
                                users.find(
                                  (user) => user.id === formik.values.userId
                                ).name +
                                " - (" +
                                users.find(
                                  (user) => user.id === formik.values.userId
                                ).email +
                                ")",
                            }
                          : null
                      }
                      onChange={(selectedOption) => {
                        formik.setFieldValue("userId", selectedOption.value);
                      }}
                      onBlur={formik.handleBlur}
                      options={users.map((user) => ({
                        value: user.id,
                        label: `${user.name} - (${user.email})`,
                      }))}
                    />
                    {formik.errors.userId && formik.touched.userId && (
                      <div className="error-message">
                        {formik.errors.userId}
                      </div>
                    )}
                  </Form.Group>
                </Col> 
                <Col sm={6} className="mb-3 text-start">
                  <Form.Label>Apply Coupon</Form.Label>
                  <Select
                    name="couponCode"
                    value={
                      formik.values.couponCode
                        ? {
                            value: formik.values.couponCode,
                            label: formik.values.couponCode,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "couponCode",
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    options={coupons.map((coupon) => ({
                      value: coupon.id,
                      label: coupon.id,
                    }))}
                  />
                  {formik.errors.couponCode && formik.touched.couponCode && (
                    <div className="error-message">
                      {formik.errors.couponCode}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="pt-3 save-remove-btn">
                  <button
                    type="submit"
                    className="custom-btn btn-3 mb-2"
                    disabled={formik.isSubmitting}
                  >
                    <span
                      className="d-flex align-items-center"
                      style={{ marginRight: formik.isSubmitting ? "5px" : "0"  }}
                    >
                      Save
                    </span>{" "} 
                    {formik.isSubmitting && <TailSpinButtonLoader />}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
        <div className="user-discount-sec">
        <div className="table-scroll">
          <Table
            columns={userDiscountColumns}
            dataSource={data}
          />
        </div>
        </div>
      </SubscriptionStyle>
    </>
  );
};

export default UserDiscount;
