import styled from "styled-components";
import scanbg from '../images/scanbg.jpg';
export const TrainingStyle=styled.section`
width: 100%;
padding: 130px 0 70px;
background: url(${scanbg});
background-repeat:no-repeat;
background-position:center;
background-size:cover;
background-attachment: fixed;
    margin: 0 auto;
    padding-top: 10rem;
    padding-bottom: 14rem;
    video {
        border: 0.125rem solid #c9c9c9;
        border-radius: 2.5rem;
        padding: 2.5rem;
        width: 100%;
    }
    .videoSection {
        background: #fff;
        padding: 37px;
    }
    .video-item {
        margin: 0 0 20px;
        border-radius: 0;
        background: #eaeaea;
        padding: 12px;
    }
    
`;