import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

// FAQ Component with Accordion
const FAQ = () => {
  const faqData = [
    {
      question: 'How do I export my manifest batch to WhatNot?',
      answer: (
        <div>
          WhatNot supports direct imports to an event via a pre-formatted CSV file. RhinoLister will prompt you for information about your WhatNot categories and format, subsequently generating a CSV file for import into your event. The file includes details such as Title, Average Retail Price, Description, and Pictures, which are directly imported into your WhatNot event. Additionally, the Identifier/Barcode becomes searchable on WhatNot by rescanning the item in the WhatNot search bar.
          <br /><br />
          Export to Whatnot by following these steps:
          <br />
          Enable Whatnot Exports:
          <ol>
            <li>Log into your RhinoLister account.</li>
            <li>Click “My Profile” on the top right corner of the site</li>
            <li>Select “Export Settings” on the left sidebar</li>
            <li>Scroll to the WhatNot section and select “Enable”</li>
            <li>Click “Save” below the Enable button</li>
          </ol>
          Export your Manifest/Batch:
          <ol>
            <li>Click the “SCAN” button from the top menu bar</li>
            <li>Scan your items. You can manually enter items or edit item details as needed.</li>
            <li>When finished scanning, select “Export” or “Export Products”</li>
            <li>Select WhatNot and fill out the available product and shipping information. Please note all items scanned will be added using the same shipping profile.</li>
            <li>Add “Description Tags” – for example, if the item is new, select “New”. Multiple items may be selected.</li>
            <li>If you wish to tag the title with the average retail price, select “Add price at end of title”. This will help your sales guys know the retail price even if the item starts at a much lower price.</li>
            <li>Click “Submit”</li>
            <li>A new CSV file should be generated and downloaded to your downloads folder.</li>
          </ol>
          Import your CSV directly to Whatnot:
          <ol>
            <li>Check WhatNot support for updates regarding how to import product information.</li>
            <li>Create an Event</li>
            <li>Open the Event and select one of the product types on the left (Auction, Buy it Now, Giveaway)</li>
            <li>Select “Quick Add”</li>
            <li>Scroll down to Import CSV (beta) and select your file.</li>
            <li>Whatnot should import each item and list them in your event automatically.</li>
          </ol>
        </div>
      ),
    },
    {
      question: 'Why do some scans return no data?',
      answer: 'Though our database will find most UPC and ASIN numbers, some items may be too new, or contain proprietary/internal barcodes such as some retailers like Amazon “X” codes or “LPN” as we work to increase our database and information, we do offer the “Manual Entry” to manually input an item and store it in your own local database for future use.',
    },
    {
      question: 'Will RhinoLister expand to other online stores?',
      answer: 'Yes, RhinoLister development team is actively working for seamless integration for direct importing of manifests to Ebay and Shopify. These features should be available in 2024',
    },
    {
      question: 'How do I sign up to sell on WhatNot?',
      answer: (
        <div>
          Registration for WhatNot selling is simple. Click our signup link to have your application fast-tracked.
          <br /><br />
          <a href="https://www.whatnot.com/invite/seller/melikeythat" target="_blank" rel="noopener noreferrer">Click Here to sign up</a>
        </div>
      ),
    },
    {
      question: 'As a reseller where do I find products to sell?',
      answer: 'Finding reliable discount products can be challenging. Fill out the Support form below and we will have a wholesale product specialist contact you within 48 hours.',
    }
  ];

  return (
    <>
      <Accordion className='mt-5 mb-5'>
        <h2 className='text-center'>FAQs</h2>
        {faqData.map((faq, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{faq.question}</Accordion.Header>
            <Accordion.Body>{faq.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default FAQ;
