import { makeRequest } from "./commonApi";

export const buyFreePlan = async (userId, accessToken) => {
  return makeRequest("/buy-free-plan", "post", { userId: userId }, accessToken);
};

export const buyPlan = async (formData, accessToken) => {
  return makeRequest("/buy-plan", "post", formData, accessToken);
};

export const checkCoupon = async (couponId, accessToken) => {
  return makeRequest(`/check-coupon/${couponId}`, "get", null, accessToken);
};

export const createCoupon = async (formData, accessToken) => {
  return makeRequest(`/create-coupon`, "post", formData, accessToken);
};

export const getCoupons = async (accessToken) => {
  return makeRequest(`/get-coupons`, "get", null, accessToken);
};

export const deleteCoupon = async (id, accessToken) => {
  return makeRequest(`/delete-coupon/${id}`, "delete", null, accessToken);
};
export const getSubscriptionPlans = async () => {
  return makeRequest("/subscription-plans", "get", null, null);
};

export const inactiveSubscriptionPlans = async () => {
  return makeRequest("/inactive-subscription-plans", "get", null, null);
};

export const cancelPlan = async (userId, planId, accessToken) => {
  return makeRequest(
    "/cancel-subscription",
    "post",
    { userId: userId, planId: planId },
    accessToken
  );
};

export const activatePlan = async (plan_id, accessToken) => {
  return makeRequest(
    "/activate-subscription-plan",
    "post",
    { plan_id: plan_id },
    accessToken
  );
};

export const deactivateUser = async (userId, accessToken) => {
  return makeRequest(
    "/deactivate-user",
    "post",
    { userId: userId },
    accessToken
  );
};

export const deactivateSubscription = async (userId, subId, accessToken) => {
  return makeRequest(
    "/deactivate-subscription",
    "post",
    { userId: userId, subId: subId },
    accessToken
  );
};

export const getPlanDiscount = async (
  email,
  subId = null,
  planId,
  accessToken
) => {
  return makeRequest(
    "/getSubscriptionDiscount",
    "post",
    { email: email, subId: subId, planId: planId },
    accessToken
  );
};

export const checkDefaultPaymentMethod = async (email, accessToken) => {
  return makeRequest(
    `/checkDefaultPaymentMethod/${email}`,
    "get",
    null,
    accessToken
  );
};
