import { createSlice } from '@reduxjs/toolkit';

const bannerSlice = createSlice({
  name: 'banners',
  initialState: {
    show:false,
    title:'',
    content: '',
    type:'banner'
  },
  reducers: {
    setBannerContent: (state, action) => {
      if(action.payload){
        const { title,content,type,is_active } = action.payload;
        state.title = title;
        state.content = content;
        state.type = type;
        state.show=is_active?true:false;
      }
    },
    setShowBanner: (state, action) => {
        if(action.payload){
          const { show } = action.payload;
          state.show = show;
        }
      },
  },
});

export const { setBannerContent ,setShowBanner} = bannerSlice.actions;
export default bannerSlice.reducer;
