import React, { useEffect, useState } from "react";
import TailSpinLoader from "../../../utils/TailSpinLoader";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { ContactFormListStyle } from "../../../assets/css/adminStyle";
import { getContactForm, deleteContactForm } from "../../../utils/api/admin";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { formatStringDate } from "../../../utils/common";
import { contactFormColumns } from "../../../utils/tableColumn";

const ContactFormList = () => {
  const [contactFormlist, setContactFormList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const accessToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      try {
        const response = await getContactForm(accessToken);
        if (response.status === 200) {
          setDataLoading(false);
          setContactFormList(response.data.data);
        } else {
          setDataLoading(false);
        }
      } catch (error) {
        setDataLoading(false);
      }
    };
    getData();
  }, []);

  if (dataLoading) {
    return (
      <ContactFormListStyle>
        <TailSpinLoader />
      </ContactFormListStyle>
    );
  }

  const deleteHandler = async (e, id) => {
    e.preventDefault();

    try {
      const result = await Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Are you sure you want to delete it?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn",
          cancelButton: "btn cancel-btn",
        },
      });

      if (result.isConfirmed) {
        const response = await deleteContactForm(id, accessToken);
        setContactFormList(response.data.data);
      } else {
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  if (contactFormlist.length === 0) {
    return (
      <div className="profile-sec">
        <h5>No data found</h5>
      </div>
    );
  }

  const data =
    contactFormlist &&
    contactFormlist.map((list, index) => {
      return {
        key: index,
        name: list.name,
        email: list.email,
        phone: list.phone,
        reason: list.reason,
        date: formatStringDate(list.created_at),
        description: list.description,
        action: (
          <FaTrash title="Delete" onClick={(e) => deleteHandler(e, list.id)} />
        ),
      };
    });

  return (
    <>
      <ContactFormListStyle>
        <div className="profile-sec">
          <h4>Contact Form List </h4>
          <div className="table-scroll">
            <Table columns={contactFormColumns} dataSource={data} />
          </div>
        </div>
      </ContactFormListStyle>
    </>
  );
};

export default ContactFormList;
