import { createSlice } from '@reduxjs/toolkit';

const accountSlice = createSlice({
  name: 'accounts',
  initialState: {
    shopify: 'disable',
    ebay: 'disable',
    whatnot: 'disable',
    hibid: 'disable',
    manifest: 'disable',
    auctionWorx:'disable',
    seller:'',
    sellerCode:'',
    auctionCatId:'',
    fixedCatId:''
  },
  reducers: {
    setOption: (state, action) => {
      if(action.payload){
      const { shopify, ebay, whatnot, hibid, manifest,auctionWorx,seller,sellerCode,auctionCatId,fixedCatId } = action.payload;
      state.shopify = shopify;
      state.ebay = ebay;
      state.whatnot = whatnot;
      state.hibid = hibid;
      state.manifest = manifest;
      state.auctionWorx = auctionWorx;
      state.seller = seller;
      state.sellerCode = sellerCode;
      state.auctionCatId = auctionCatId;
      state.fixedCatId = fixedCatId;
      
      }
    },
  },
});
export const { setOption } = accountSlice.actions;
export default accountSlice.reducer;
