import React, { useEffect, useState } from "react";
import { SubscriptionStyle } from "../../../assets/css/adminStyle";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { Table } from "antd";
import { DiscountSchema } from "../../../utils/validations";
import { discountColumns } from "../../../utils/tableColumn";
import { createCoupon,getCoupons,deleteCoupon} from "../../../utils/api/subscription";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState("");
  const accessToken = useSelector((state) => state.auth.token);

  const fetchCoupons = async () => {
    try {
      const response = await getCoupons(accessToken);
      if (response.status == 200) {
        setError("");
        setCoupons(response.data.coupons.data);
      } else {
        setError("Something went wrong.Please try again.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCoupons();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      id: "",
      percentOff: "10",
    },
    validationSchema: DiscountSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const response = await createCoupon(values, accessToken);
      if (response.status === 200) {
        setError("");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Coupon created Successfully!",
          customClass: {
            confirmButton: "btn",
          },
        }).then(() => {
          resetForm();
          fetchCoupons();
        });
      } else {
        setError("Something went wrong.Please try again.");
      }
    },
  });

  const deleteHandler = async (e, id) => {
    e.preventDefault();
    try {
      const result = await Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Are you sure you want to delete it?",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn",
          cancelButton: "btn cancel-btn",
        },
      });

      if (result.isConfirmed) {
        const response = await deleteCoupon(id, accessToken);
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Coupon deleted Successfully!",
            customClass: {
              confirmButton: "btn",
            },
          }).then(() => {
            fetchCoupons();
          });
        }
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const data =
    coupons &&
    coupons.map((list, index) => {
      console.log(coupons);
      return {
        key: index,
        name: list.name,
        id: list.id,
        percentOff: list.percent_off ? list.percent_off + "%" : "-",
        action: (
          <div style={{ cursor: "pointer" }}>
            <FaTrash
              title="Delete"
              onClick={(e) => deleteHandler(e, list.id)}
            />
          </div>
        ),
      };
    });

  return (
    <>
      <SubscriptionStyle>
        <Form onSubmit={formik.handleSubmit}>
          <div className="subscription-plan-container">
            <div className="profile-sec">
              <Row>
                <Col sm={12} className="text-start">
                  <h4>Add Coupon</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3 text-start">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className="error-message">{formik.errors.name}</div>
                  )}
                </Col>
                <Col sm={6} className="mb-3 text-start">
                  <Form.Label htmlFor="subHeading">Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.id && formik.touched.id && (
                    <div className="error-message">{formik.errors.id}</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3 text-start">
                  <Form.Label>Percentage Discount</Form.Label>
                  <Form.Select
                    name="percentOff"
                    value={formik.values.percentOff}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                    <option value="30">30%</option>
                    <option value="40">40%</option>
                    <option value="50">50%</option>
                  </Form.Select>
                  {formik.errors.percentOff && formik.touched.percentOff && (
                    <div className="error-message">
                      {formik.errors.percentOff}
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className="pt-3 save-remove-btn">
                  <button
                    type="submit"
                    className="custom-btn btn-3 mb-2"
                    disabled={formik.isSubmitting}
                  >
                    <span
                      className="d-flex align-items-center"
                      style={{ marginRight: formik.isSubmitting ? "5px" : "0" }}
                    >
                      Save
                    </span>{" "}
                    {formik.isSubmitting && <TailSpinButtonLoader />}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {!error && (
            <div className="user-discount-sec">
              <div className="table-scroll">
                <Table columns={discountColumns} dataSource={data} />
              </div>
            </div>
          )}
      </SubscriptionStyle>
    </>
  );
};

export default Coupon;
