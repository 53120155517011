import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link ,NavLink} from "react-router-dom";
import { useSelector } from 'react-redux';
import { FaFacebookF ,FaInstagram} from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { MdEmail,MdCopyright } from "react-icons/md";
import { FooterStyle } from "../../assets/css/commonStyle";
import { scrollToTop } from "../../utils/common";

const Footer = () => {
  const isAuthenticated = useSelector((state)=> state.auth.isAuthenticated);
  
  return (
    <FooterStyle className="footer">
      <Container>
        <Row className="justify-content-between">
          <Col md="5" className="text-start footer-content"> 
            <h3 className="ft-heading">Rhinolister</h3>
            <ul className="d-flex social-icon mt-4">
              <li>
                <Link to="https://www.facebook.com/rhinolister" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF />
                </Link>
              </li>
              {/* <li>
                <Link to="https://www.twitter.com/rhinolister" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </Link>
              </li> */}
              <li>
                <Link to="https://www.instagram.com/rhinolisterscan" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </Link>
              </li>
              <li>
              <Link to="https://www.tiktok.com/@rhinolister" target="_blank" rel="noopener noreferrer">
              <FaTiktok/>
              </Link>
              </li>
            </ul>

          </Col>
          <Col md="2" >
            <div className="ft-heading">Links</div>
            <ul>
              <li>
                <NavLink
                to="/"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
                Home
              </NavLink>
              </li>
              <li>
                {/* <Link to="/scan" onClick={scrollToTop}>Scan</Link> */}
                <NavLink
                to="/scan"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
                Scan
              </NavLink>
              </li>
              <li>
              <NavLink
                to="/subscription"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                }
              >
                {!isAuthenticated ? 'Pricing' : 'Subscription'}
              </NavLink>
                {/* <Link to="/subscription" onClick={scrollToTop}>{isAuthenticated ? 'Subscription':'Pricing'}</Link> */}
              </li>
            </ul>
          </Col>
          <Col md="2" >
            <div className="ft-heading">Support</div>
            <ul>
              <li>
                {/* <Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link> */}
                <NavLink
                to="/privacy-policy"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
                Privacy Policy
              </NavLink>
              </li>
              <li>
                {/* <Link to="/terms-of-service" onClick={scrollToTop}>Terms of Service</Link> */}
                <NavLink
                to="/terms-of-service"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
               Terms of Service
              </NavLink>
              </li>
              {isAuthenticated ? 
              <>
              <li>
                {/* <Link to="/training-videos" onClick={scrollToTop}>Training Videos</Link> */}
                <NavLink
                to="/training-videos"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
               Training Videos
              </NavLink>
              </li>
              <li>
                {/* <Link to="/support" onClick={scrollToTop}>Support</Link> */}
                <NavLink
                to="/support"
                onClick={scrollToTop}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "isActive" : ""
                } 
              >
               Support
              </NavLink>
              </li>
              </>:''
                }
            </ul>
          </Col>

          <Col md="3" className="footer-contact-us ">
            <div className="ft-heading" >Contact Us</div>
            <div>
            <p >
              <a href="mailto:support@rhinolister.com" style={{ textDecoration: 'none', color: '#cfcfcf' }}>
                <span><MdEmail /></span> support@rhinolister.com
              </a>
            </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={12}>
            <div className="copywrite text-center">
              {" "}
              <p>Copyright <MdCopyright />2024 All rights reserved | rhinolister.com</p>
            </div>
          </Col>
        </Row>
      </Container>
    </FooterStyle>
  );
};

export default Footer;