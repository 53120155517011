import styled from "styled-components";

export const WhatNotStyle = styled.div`
  .export-row {
    margin-bottom: 10px;
    align-items: center;
    background: #efefef;
    padding: 16px;
    border-radius: 16px;
    .css-14ovgjo-control{min-height:34px; padding-top:0px;padding-bottom:0; }
    .css-3w2yfm-ValueContainer{
      padding:0;
    }
    .css-1xc3v61-indicatorContainer {
      outline:none;
  }
  .css-13cymwt-control{min-height:33px;}
  .css-t3ipsp-control:hover{border:1px solid #dee2e6; box-shadow:none;min-height:33px;}
  .css-b62m3t-container{font-size:14px}
}`;