import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Col, Row, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";
import { useDispatch, useSelector } from "react-redux";
import { saveAuctionWorxData } from "../../../utils/api/accountSetting";
import { auctionValidationSchema } from "../../../utils/validations";

const AuctionWorx = ({
  user,
  options, 
  setOption,
  showOptions,
  optionData,
  handleOptionChange,
  validationError,
}) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.token);

  const formik = useFormik({
    initialValues: {
      auctionCatId: "",
      fixedCatId: "",
    },
    validationSchema:
      optionData.auctionWorx === "enable" ? auctionValidationSchema : null,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const response = await saveAuctionWorxData(
        values,
        optionData.auctionWorx,
        user.id,
        accessToken
      );

      if (response.status === 200) {
        dispatch(
          setOption({
            ...options,
            auctionWorx: optionData.auctionWorx,
            auctionCatId: values.auctionCatId,
            fixedCatId: values.fixedCatId,
          })
        );
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data saved successfully.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn",
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Something went wrong. Please try again.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn",
          },
        });
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      auctionCatId: optionData["auctionCatId"],
      fixedCatId: optionData["fixedCatId"],
    });
  }, [optionData]);

  return (
    <div className="col-sm-12">
      <div className="profile-sec">
        <div className="tp-label">
          <span>AuctionWorx</span>
        </div>
        <Form.Check
          inline
          label="Enable"
          name="auctionWorx"
          type="radio"
          value="enable"
          id="auctionWorxEnable"
          htmlFor="auctionWorxEnable"
          checked={optionData.auctionWorx === "enable"}
          onChange={handleOptionChange}
        />
        <Form.Check
          inline
          label="Disable"
          name="auctionWorx"
          type="radio"
          value="disable"
          id="auctionWorxDisable"
          htmlFor="auctionWorxDisable"
          checked={optionData.auctionWorx === "disable"}
          onChange={handleOptionChange}
        />
        {optionData.auctionWorx === "disable" ? (
          ""
        ) : (
          <Row>
            <Col sm={6} className="mt-2">
              <Form.Label htmlFor="inputText">Auction Category Id</Form.Label>
              <Form.Control
                type="text"
                id="auctionCatId"
                name="auctionCatId"
                aria-describedby="textHelpBlock"
                value={formik.values.auctionCatId || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.auctionCatId && formik.errors.auctionCatId && (
                <p className="text-start error-message">
                  {formik.errors.auctionCatId}
                </p>
              )}
            </Col>
            <Col sm={6} className="mt-2">
              <Form.Label htmlFor="inputText">
                Buy It Now Category Id
              </Form.Label>
              <Form.Control
                type="text"
                id="fixedCatId"
                name="fixedCatId"
                aria-describedby="textHelpBlock"
                value={formik.values.fixedCatId || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fixedCatId && formik.errors.fixedCatId && (
                <p className="text-start error-message">
                  {formik.errors.fixedCatId}
                </p>
              )}
            </Col>
          </Row>
        )}
        <div
          className="mt-3 col-sm-12 "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            type="button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting || !showOptions.auctionWorx}
            className="custom-btn btn-3"
          >
            <span>Save</span> {formik.isSubmitting && <TailSpinButtonLoader />}
          </button>
          {validationError && validationError.auctionWorx && (
            <div className="error-message">{validationError.auctionWorx}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionWorx;
