import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const TailSpinLoader = ({ color = '#E7A83E', height = 50, width = 50 }) => {
  return (
    <div className="loader-container">
      <TailSpin color={color} height={height} width={width} />
    </div>
  );
};

export default TailSpinLoader;


export const TailSpinButtonLoader=()=>{
    return   <TailSpin color="#E7A83E" height={18} width={18} />;
}
