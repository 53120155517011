import React from 'react';
import { BannerStyle } from '../assets/css/homeStyle';
import { useDispatch } from "react-redux";
import { setShowBanner } from '../redux/slices/bannerSlice';

export const Banner = ({ title,content }) => {
  const dispatch = useDispatch();
  const closeHandle = () => {
    dispatch(setShowBanner({ show: false }));
  }
  return <BannerStyle>
    <div className='container'>
      <div className='row align-items-center h-100'>
        <div className='col-md-10'>
          <div className='mobile-row'>
            <p>{title}</p>
            <p> 
              {content}
            </p>
          </div>
        </div>
        <div className='col-md-2'>
          <div className='got-it'>
            <button className="btn" onClick={closeHandle}>Got It</button>
          </div>
        </div>
      </div>
    </div>
  </BannerStyle>
}
