import React, { useEffect, useState } from "react";
import { UserListStyle } from "../../../assets/css/adminStyle";
import { useSelector } from "react-redux";
import { fetchUserList } from "../../../utils/api/admin";
import { formatStringDate } from "../../../utils/common";
import { Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { userListColumns } from "../../../utils/tableColumn";
import { deactivateUser } from "../../../utils/api/subscription";
import TailSpinLoader from "../../../utils/TailSpinLoader";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";
import Swal from "sweetalert2";
import { exportUsers } from "../../../utils/common";

const UserList = () => {
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userlist, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState({ userId: "", loading: false });
  const accessToken = useSelector((state) => state.auth.token);

  const handleSearchUser = (e) => {
    setSearchText(e.target.value);
  };

  const getUsers = async () => {
    try {
      const response = await fetchUserList(accessToken);
      if (response.status === 200) {
        setDataLoading(false);
        const data = await response.data;
        setUserList(data?.users);
        setFilterData(data?.users);
      } else {
        setDataLoading(false);
        setError("Failed to fetch users");
      }
    } catch (error) {
      setError("Failed to fetch users");
      setDataLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUsers();
  }, []);

  useEffect(() => {
    const filterUsers = userlist.filter(
      (record) =>
        record.name.toLowerCase().includes(searchText.toLowerCase()) ||
        record.email.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilterData(filterUsers);
  }, [searchText]);

  if (dataLoading) {
    return <TailSpinLoader />;
  }

  const deleteHandler = (userId) => {
    Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: "Are you sure you want to deactivate this user?",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn",
        cancelButton: "btn cancel-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setBtnLoading({ userId, loading: true });
        try {
          const response = await deactivateUser(userId, accessToken);
          setBtnLoading({});
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "User deactivated Successfully!",
              customClass: {
                confirmButton: "btn",
              },
            }).then(() => {
              setError("");
              getUsers();
            });
          } else {
            setError("Something went wrong.Please try again.");
            console.error("Error fetching subscription plans");
          }
        } catch (error) {
          setError("Something went wrong.Please try again.");
          console.error("An error occurred", error);
        }
      }
    });
  };

  return (
    <>
      <UserListStyle>
        <div className="profile-sec">
          <h4>User List </h4>
          <Space style={{ marginBottom: 16 }}>
            <Input
              placeholder="Search by Name or Email"
              value={searchText}
              onChange={handleSearchUser}
              prefix={<SearchOutlined />}
            />
          </Space>
          <Link
            to="#"
            className="custom-btn btn-3"
            style={{ float: "right" }}
            onClick={(e) => {
              e.preventDefault();
              exportUsers(filterData);
            }}
          >
            Export Users
          </Link>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {filterData.length > 0 && (
            <>
                <div className="table-scroll">
                  <Table
                    columns={userListColumns}
                    dataSource={filterData.map((user, index) => {
                      return {
                        key: index,
                        id: index + 1,
                        name: user.name,
                        email: user.email,
                        plan: user.plan ? user.plan : "-",
                        created: formatStringDate(user.created_at),
                        action: (
                          <button
                            className="btn d-flex"
                            disabled={btnLoading?.loading == true}
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(user.id)}
                          >
                            <span
                              style={{
                                marginRight:
                                  btnLoading?.loading == true ? "10px" : "0",
                              }}
                            >
                              Deactivate
                            </span>{" "}
                            {btnLoading?.userId == user.id &&
                              btnLoading?.loading == true && (
                                <TailSpinButtonLoader />
                              )}
                          </button>
                        ),
                      };
                    })}
                  />
                </div>
            </>
          )}
        </div>
      </UserListStyle>
    </>
  );
};

export default UserList;
