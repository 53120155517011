import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import scan from "../../assets/images/scan-sub.svg"
import { ContentStyle } from "../../assets/css/subscriptionStyle";

const Content = () => {
  return (
    <ContentStyle className="content-sec">
      <Container>
        <Row className="align-items-center">
          <Col sm={8} className="order-2 order-sm-1">
            <h2>Subscriptions</h2>
            <p>
              Welcome to our subscription selection, tailored for every reseller, big or small. Kickstart your journey with our Free plan, perfect for accessing basic product information on the go. For more depth, our Basic plan is ideal, offering capabilities to build product lists, view images, and simple exporting tools for emerging resellers. As your business grows, our Advanced plan elevates your strategy without stretching your budget, allowing direct exports to stores and customizable formats for seamless reseller platform integration. Lastly, our Enterprise plan caters to full-time resellers seeking to maximize efficiency and expand their listings without limits. Select the plan that aligns with your ambitions and scale your reselling business to new heights.
            </p>
          </Col>
          <Col sm={4} className="text-sm-end text-center pb-4 pb-sm-0  order-1 order-sm-2">
            <img src={scan} />
          </Col>
        </Row>
      </Container>
    </ContentStyle>
  );
};

export default Content;