import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { styled } from "styled-components";
import scanbg from "../assets/images/scanbg.jpg";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import TailSpin from "../utils/TailSpinLoader";
import { useSelector } from "react-redux";
import { checkCoupon } from "../utils/api/subscription";
  
const Code = () => {
  const { code } = useParams();
  const [loading,setLoading]=useState(true);
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.token);

  const checkCouponHandler = async () => {
    const response = await checkCoupon(code, accessToken);
    if (response.status == 200) {
      Cookies.set("discountCode", code, { expires: 0.5 });
    }
    setLoading(false);
    navigate('/');
  };

  useEffect(() => {
    checkCouponHandler();
  }, [code]);

 
  return (
    <Wrapper className="training-section">
         {loading ? 
        <div className="loading-scan">
          <div className="loader-container">
            <TailSpin color="#E7A83E" height={80} width={80} />
          </div> 
        </div> : 

      <Container>
        <div className="row justify-content-center">
          <h3>Advertisement Page</h3>
        </div>
      </Container>}
    </Wrapper>
  );
};

export default Code;

const Wrapper = styled.section`
  width: 100%;
  padding: 130px 0 70px;
  background: url(${scanbg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: 450px;
  .container .col-md-10 {
    background: rgba(255, 255, 255, 0.7);
    padding: 50px 60px;
    border-radius: 10px;
    h3 {
      text-align: center;
    }
  }
  .loading-scan {
    background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 1;
  }
`;
